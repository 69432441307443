<template>
    <div>
        <el-dialog :visible.sync="show" width="50%">
            <div class="loginPage">
                <div class="leftImg">
                    <img :src="!isReset?loginBg:pwdBg">
                </div>
                <div class="rightForm">
                    <div class="logo">
                        <img class="companyImg" src="@/assets/image/companyImg.png" alt="">
                    </div>
                    <div class="loginType" v-if="!isReset">
                        <span :class="type==1?'activeType cursor':'cursor'" @click="type=1">账号登录</span>
                        <span :class="type==2?'activeType cursor':'cursor'" @click="type=2">验证码登录</span>
                    </div>
                    <div class="resetTitle" v-if="isReset">重置密码</div>
                    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" size="medium">
                        <el-form-item prop="phone">
                            <el-input :prefix-icon="User" type="number" v-model="loginForm.phone" auto-complete="off" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                        <!-- 更改密码的验证码排上面 -->
                        <el-form-item v-if="isReset">
                            <check-code width="63%" :phone="loginForm.phone" @inputcode="loginForm.phoneCode=$event"></check-code>
                        </el-form-item>
                        <el-form-item prop="password" v-if="type==1 || isReset">
                            <el-input :prefix-icon="Lock" v-model="loginForm.password" type="password" auto-complete="off" placeholder="密码"></el-input>
                        </el-form-item>
                        <!-- 登录的验证码排下面 -->
                        <el-form-item v-if="type==2">
                            <check-code width="63%" :phone="loginForm.phone" @inputcode="loginForm.phoneCode=$event"></check-code>
                        </el-form-item>
                        <el-form-item prop="verCode" v-if="captchaOnOff && !isReset">
                            <div class="rows">
                                <el-input :prefix-icon="CircleCheck" v-model="loginForm.verCode" placeholder="验证码" style="width: 63%"></el-input>
                            <div class="login-code">
                                <img :src="codeUrl" @click="getCode" class="login-code-img" />
                            </div>
                            </div>
                        </el-form-item>
                        <el-form-item style="width:100%;">
                            <el-button :loading="loading" type="primary" class="loginBtn" @click="handleLogin">
                                <span class="loginTxt" v-if="!loading">{{isReset?'重 置':'登 录'}}</span>
                                <span class="loginTxt" v-else>{{isReset?'重 置 中...':'登 录 中...'}}</span>
                            </el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getCodeImg, postUserLogin, postResetPwd } from "@/api/home";
import {getUserDetail} from '@/api/company'
import loginBg from '@/assets/image/loginbg.png';
import pwdBg from '@/assets/image/pwdBg.png';
import checkCode from "./checkCode.vue";
import { User, Lock, CircleCheck } from "element-ui";
import { setToken } from "@/utils/token";

export default {
    components: {
        checkCode,
    },
    name: "Login",
    props: {
        isReset: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loginBg,
            pwdBg,
            show: false,
            loading: false,
            type: "1", //1账号，2验证码
            codeUrl: "",
            loginForm: {
                phone: "",
                password: "",
                phoneCode: "",
                verCode: "",
                uuid: "",
            },
            loginRules: {
                phone: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入您的账号",
                    },
                ],
                password: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入您的密码",
                    },
                ],
                verCode: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请输入验证码",
                    },
                ],
            },
            loading: false,
            // 验证码开关
            captchaOnOff: true,
            // 注册开关
            register: false,
            redirect: undefined,
            User,
            Lock,
            CircleCheck,
        };
    },
    created() {
        if (this.isReset) {
            this.loginForm.phone = localStorage.getItem("phone_key");
        } else {
            this.getCode();
        }
    },
    methods: {
        getCode() {
            getCodeImg().then((res) => {
                this.captchaOnOff =
                    res.captchaOnOff === undefined ? true : res.captchaOnOff;
                if (this.captchaOnOff) {
                    this.codeUrl = "data:image/gif;base64," + res.img;
                    this.loginForm.uuid = res.uuid;
                }
            });
        },
        async handleLogin() {
            let isErr = false;

            this.$refs.loginForm.validate((valid) => {
                if (!valid) {
                    isErr = true;
                }
            });
            if (isErr) {
                return;
            }

            if (!this.loginForm.phoneCode && this.type == 2) {
                this.$message({
                    type: "error",
                    message: "请输入验证码",
                });
                return;
            }

            if (this.isReset) {
                this.userResetPwd();
                return;
            }

            if (this.type == 1) {
                this.loginForm.phoneCode = "";
            } else {
                this.loginForm.password = "";
            }
            this.loading = true;
            postUserLogin(this.loginForm)
                .then(async (res) => {
                    console.log('用户登录：',res);
                    this.loading = false;
                    localStorage.setItem("phone_key", this.loginForm.phone);
                    localStorage.setItem("userInfo_key",JSON.stringify(res.user));
                    setToken(res.token);
                    localStorage.setItem('userRole_key',res.user.userRoleCode);
                    this.show = false;
                    this.$emit("login");
                    this.$router.go(0);
                })
                .catch(() => {
                    this.getCode();
                    this.loading = false;
                });
        },
        //用户重置密码
        async userResetPwd() {
            delete this.loginForm.verCode;
            delete this.loginForm.uuid;
            postResetPwd(this.loginForm)
                .then((res) => {
                    this.loading = false;
                    this.show = false;
                    this.$emit("login");
                     this.$message({
                        type: "success",
                        message: "修改成功",
                    });
                })
                .catch(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="less" scoped>
.title {
    margin: 0px auto 0.3rem auto;
    text-align: center;
    color: #707070;
}

.login-form {
    border-radius: 6px;
    background: #ffffff;
    width: 4rem;
    padding: 0.25rem 0.25rem 0.05rem 0.25rem;
    :deep(.el-input) {
        height: 0.42rem;
        input {
            height: 0.42rem;
        }
    }
    :deep(.el-icon) {
        font-size: 0.18rem !important;
    }
}
.login-tip {
    font-size: 0.13rem;
    text-align: center;
    color: #bfbfbf;
}
.login-code {
    width: 32%;
    height: 0.42rem;
    margin-left: 0.1rem;
    img {
        cursor: pointer;
    }
}
.login-code-img {
    height: 0.42rem;
}
.loginPage {
    display: flex;
    align-items: center;
    .leftImg {
        width: 5.13rem;
        height: 7rem;
        img {
            height: 100%;
            width: 100%;
        }
    }
    .rightForm {
        .logo{
            margin-bottom: 1.2rem;
           text-align: center;

            .companyImg{
            height: .84rem;
            width: 3.78rem;
        }
        }
        
        .loginType {
            span {
                margin-right: 0.13rem;
                font-size: 0.18rem;
                font-weight: 600;
                color: #333e51;
            }
            .activeType {
                font-size: 0.24rem;
                color:  var(--themeColor);
            }
        }
    }
}
.resetTitle {
    font-size: 24px;
    font-weight: 600;
    color:  var(--themeColor);
}
/deep/.el-dialog {
    border-radius: 0.15rem !important;
}
/deep/.el-dialog__header {
    padding: 0 !important;
}
/deep/.el-dialog__footer {
    padding: 0 !important;
}
/deep/.el-dialog__body{
    padding: 0 !important;
}

.loginTxt{
    font-size: .18rem;
}
.loginBtn{
    background:  var(--themeColor);
    width:100%;height:.42rem;
    padding: 0 !important;
}
/deep/.el-input__inner{
    height: .36rem !important;
    line-height: .36rem !important;
}


.rows{
    display: flex;
    white-space: nowrap;
}
</style>
