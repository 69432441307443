<template>
    <div class="authForm">
        <el-dialog class="show" :visible.sync="show" width="50%">
            <div class="authPadding">
                <h1>个人认证</h1>
                <el-form ref="userForm" :model="userForm" :rules="authRules" class="login-form" size="medium ">
                    <el-form-item prop="cardName" label="身份证姓名">
                        <el-input type="text" v-model="userForm.cardName" auto-complete="off" placeholder="请输入身份证姓名" size="default"></el-input>
                    </el-form-item>
                    <el-form-item prop="cardCode" label="身份证号码">
                        <el-input type="number" v-model="userForm.cardCode" auto-complete="off" placeholder="请输入身份证号码" size="default"></el-input>
                    </el-form-item>
                    <el-form-item prop="phone" label="手机号码">
                        <el-input type="number" v-model="userForm.phone" auto-complete="off" placeholder="请输入手机号码" disabled size="default"></el-input>
                    </el-form-item>
                    <h2 v-if="type==1">角色管理</h2>
                    <el-form-item prop="roleId" label="角色类型" v-if="type==1">
                        <el-radio-group v-model="userForm.roleId" @change="changeRadio">
                            <el-radio-button v-for="(item,index) in roleList" :key="index" :label="item.id">{{item.name}}</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <div v-if="formdataList.length>0 && type==1">
                        <draggable-item v-for="(element, index) in formdataList" :key="element.renderKey" :drawing-list="formdataList" :element="element" :index="index" :form-conf="formConf" />
                    </div>
                </el-form>
                <div class="btnCenter" style="margin-top:.7rem">
                    <span class="authBtn cursor" @click="clickAuthUser">认 证</span>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import { getUserRole, postSubmitUser } from "@/api/apply.js";
import { getFormDetail } from "@/api/home.js";
import checkCode from "@/components/login/checkCode.vue";
import draggableItem from "@/views/home/viewDraggableItem";
import { formConf } from "@/utils/form/formConfig";
export default {
    components: {
        checkCode,
        draggableItem,
    },
    props: {
        type: Number,
    },
    data() {
        return {
            roleList: [],
            show: false,
            formConf,
            formdataList: [],
            formId: "",
            userForm: {
                cardName: "",
                cardCode: "",
                phone: "",
                roleId: "",
                jsonData: "",
            },
            authRules: {
                cardName: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入身份证姓名",
                    },
                ],
                cardCode: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入身份证号",
                    },
                ],
                roleId: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择角色",
                    },
                ],
            },
        };
    },
    created() {
        this.loadGetRole();
        this.userForm.phone = localStorage.getItem("phone_key");
    },
    methods: {
        async loadGetRole() {
            let res = await getUserRole();
            if (res.code == 200) {
                this.roleList = res.data;
                if (this.type == 1) {
                    this.userForm.roleId = this.roleList[0].id;
                    this.formId = this.roleList[0].authForm;
                    this.loadGetForm();
                }
            }
        },
        async loadGetForm() {
            let res = await getFormDetail(this.formId);
            if (res.code == 200) {
                this.formdataList = res.data.controlsJson;
            }
        },
        //更改角色
        changeRadio() {
            this.roleList.forEach((item) => {
                if (item.id == this.userForm.roleId) {
                    this.formId = item.authForm;
                    if (item.authForm) {
                        this.loadGetForm();
                    } else {
                        this.formdataList = [];
                    }
                }
            });
        },
        //用户认证
        async clickAuthUser() {
            let isErr = false;
            this.$refs["userForm"].validate((valid) => {
                if (!valid) {
                    isErr = true;
                }
            });

            if (isErr) {
                return;
            }
            if (this.type == 1) {
                this.formdataList.forEach((item) => {
                    if (item.required) {
                        if (
                            !item.defaultValue ||
                            item.defaultValue.length == 0
                        ) {
                            console.log("未输入", item);
                            isErr = true;
                        }
                    }
                });
                if (isErr) {
                    this.$message.error("有必填未输入");
                    return;
                }
            }

            this.userForm.jsonData = JSON.stringify(this.formdataList);
            let res = await postSubmitUser(this.userForm);
            if (res.code == 200) {
                this.$message({
                    type: "success",
                    message: "提交成功",
                });
                this.show = false;
                this.$emit("submit");
            }
        },
    },
};
</script>

<style lang="less" scoped>
.authPadding {
    padding: 0.1rem 0.45rem;
    h1 {
        margin-bottom: 0.3rem;
        font-size: 0.21rem !important;
    }

    h2 {
        margin-top: 0.5rem;
        margin-bottom: 0.15rem;
        font-size: 0.21rem !important;
    }
}
.authBtn {
    display: inline-block;
    width: 1.2rem;
    height: 0.5rem;
    background: #025cec;
    color: #fff;
    border-radius: 0.05rem;
    font-size: 0.18rem;
    line-height: 0.5rem;
}
:deep(.el-radio-button__inner) {
    width: 1.08rem !important;
    height: 0.45rem;
    margin-right: 0.2rem;
    font-size: 0.18rem !important;
    font-weight: 400;
    padding: 0;
    line-height: 0.45rem;
    color: #333333;
    border-radius: 0.04rem !important;
    background-color: #f5f5f5;
    border: none !important;
}
:deep(.el-form-item__label) {
    font-size: 0.18rem;
}
</style>