<template>
    <div class="infoPage">
        <img class="topImg" src="@/assets/image/usercenter.png" alt="">
        <div class="info">
            <div class="user">
                <div class="title">
                    <img src="@/assets/image/userInfo.png">
                    我的信息
                </div>
                <div class="rowsInfo">
                    <div class="rows">
                        <p><span>平台用户ID：</span>{{userInfo.id}}</p>
                        <p><span>手机号：</span>{{userInfo.phone}}</p>
                        <p> <span>注册日期：</span>{{userInfo.createTime}}</p>
                        <p><span>用户角色：</span>{{userInfo.roleName}}
                            <span class="cursor theme_color renzheng" @click="clickUserAuth(1)">角色认证</span>
                        </p>

                    </div>
                    <div class="rows">
                        <p><span>用户名：</span>{{userInfo.userName}}</p>
                        <p><span>联系邮箱：</span>{{userInfo.email}}</p>
                        <p> <span>真实姓名：</span>{{userInfo.cardName}}
                        <span class="cursor theme_color renzheng" @click="clickUserAuth(2)">个人认证</span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="company user">
                <div class="title">
                    <img src="@/assets/image/companyInfo.png">
                    公司信息
                </div>
               <div class="row">
                 <div v-if="!isCompany" class="qiyerenzheng cursor" @click="openCompanyInfo">企业认证</div>
                <div v-if="!isCompany" class="qiyerenzheng cursor" @click="openCompanyInfo">企业绑定</div>
               </div>
                <div class="rowsInfo" v-if="isCompany">
                    <div class="rows">
                        <p><span>企业名称：</span>{{companyInfo.name}}</p>
                        <p><span>营业执照：</span>{{companyInfo.businessLicenseCode}}</p>
                        <p> <span>法人代表：</span>{{companyInfo.legalPerson}}</p>

                    </div>
                    <div class="rows">
                        <p><span>企业类型：</span>{{companyInfo.type==0?'企业':'个体工商户'}}</p>
                        <p><span>法人身份证：</span>{{companyInfo.legalPersonCard}}</p>
                    </div>
                </div>
            </div>
        </div>
        <userAuth ref="userAuth" @submit="loadGtUserInfo" :type="accType"></userAuth>
        <companyAuth ref="companyAuth"  @submit="loadGtUserInfo"></companyAuth>
    </div>
</template>

<script>
import { getUserDetail } from "@/api/home.js";
import userAuth from './auth.vue';
import companyAuth from './companyAuth.vue'
export default {
    components:{
        userAuth,
        companyAuth
    },
    data() {
        return {
            accType:null,
            userInfo: {},
            isCompany: true,
            companyInfo: {
                name: "",
                businessLicenseCode: "",
                legalPerson: "",
                tyep: "",
                legalPersonCard: "",
            }, //企业信息
        };
    },
    created() {
        this.loadGtUserInfo();
    },
    methods: {
        async loadGtUserInfo() {
            let res = await getUserDetail();
            if (res.code == 200) {
                this.userInfo = res.data.userInfo;
                if (res.data.enterpriseInfo) {
                    this.companyInfo = res.data.enterpriseInfo;
                } else {
                    this.isCompany = false;
                }
            }
        },
        //点击个人认证
        clickUserAuth(type){
            this.accType=type;
            this.$refs.userAuth.show=true;
        },
        //获取用户认证数据
        getUserAuthData(val){

        },
        //打开企业认证信息
        openCompanyInfo(){
            this.$refs.companyAuth.show=true;
        }
    },
};
</script>

<style lang="less">
body {
    background: #f9f9f9;
}
.infoPage {
    position: relative;
    height: 100%;
    background: #f9f9f9;
}
.topImg {
    height: 6.7rem;
}
.info {
    padding: 0 2.6rem;

    .user {
        width: 100%;
        height: 2.7rem;
        margin: 0.3rem 0;
        padding: 0.4rem 0.45rem;
        background: #fff;
        border-radius: 0.06rem;
        .title {
            margin-bottom: 0.2rem;
            font-size: 0.21rem;
            font-weight: 400;
            color: #333e51;
        }
        .rowsInfo {
            padding-left: 0.2rem;
            .rows {
                display: inline-block;
                width: 50%;
                p {
                    margin-bottom: 0.13rem;
                    font-size: 0.18rem;
                    font-weight: 400;
                    color: #333;
                    span {
                        color: #666666;
                    }
                }
            }
        }
    }

    .renzheng {
        text-decoration: underline;
    }
    .row{
        display: flex;
        justify-content: space-between;
    }
    .qiyerenzheng {
        width: 48%;
        height: .7rem;
        background: #f2f7ff;
        border: 1px dashed #025cec;
        font-size: .20rem;
        line-height: .7rem;
        text-align: center;
        text-decoration: underline;
        font-weight: 500;
        color: #025cec;
        border-radius: .06rem;
    }
}
</style>