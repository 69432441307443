<template>
    <div>
        <div class="topPage">
            <img class="leftIcon cursor" :src="logoUrl" @click="clickLogo" alt="">
            
            <div v-if="type=='index'" class="centerOptions">
                <div  class="item" v-for="(item,index) in navData" :key="index" @click="clickItemOption(index,item)">
                    <span class="itemTxt font18" @mouseover="mouseOver(index)" @mouseleave="mouseLeave(index)">{{item.name}}</span>
                    <div :class="activeOption==index?'active theme_bg':''" @mouseover="mouseOver(index)" @mouseleave="mouseLeave(index)"></div>

                    <div class="select" ref="xuanting" @mouseover="mouseOver(index)" @mouseleave="mouseLeave(index)">
                        <div class="options" v-for="item1 in item.navigationBarList" :key="item1.id" @click.stop="clickItemOption(index,item1)">{{item1.name}}</div>
                    </div>
                </div>
            </div>
            <div v-if="type=='page'" class="centerOptions">
                <div  class="item">
                    <span class="itemTxt font18" @click="clickLogo">首页</span>
                    <div></div>
                </div>
                <div  class="item">
                    <span class="itemTxt font18">{{title}}</span>
                    <div class="active theme_bg"></div>
                </div>
            </div>
            <div v-if="isLogin">
                <el-button v-if="!isToken" @click="clickOpenRegister" class="font16">注册</el-button>
                <el-button v-if="!isToken" @click="clickOpenLogin" class="theme_bg font16" type="primary">登录</el-button>
                <div class="userINfo" v-if="isToken">
                    <!-- <el-button v-if="type=='index'" @click="clickNavUrl" class="font16 setColor">工作台</el-button> -->
                    <el-dropdown @command="handleCommand" @visible-change="chagneVisble">
                           <img class="userImg cursor" src="@/assets/image/userIco.jpeg">
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="1" >个人中心</el-dropdown-item>
                            <el-dropdown-item command="2" >重置密码</el-dropdown-item>
                            <el-dropdown-item command="5" v-show="!isUser" >{{isCompanyUser?'项目申请':'案例审批'}}</el-dropdown-item>
                            <el-dropdown-item v-show="isCheck" command="4" >我的企业</el-dropdown-item>
                            
                            <el-dropdown-item command="3" >退出登录</el-dropdown-item>
                            
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <div class="topHeight"></div>
        <register ref="userRegister"></register>
        <login :isReset="reset" ref="userLogin" @login="successLogin"></login>
    </div>
</template>

<script>
import { isToken, removeToken } from "@/utils/token";
import { getNavData, getLoginSwitch } from "@/api/home.js";
import register from "@/components/register/index";
import login from "@/components/login/index";
import roleList from '@/utils/role.js'

import whiteImg from '@/assets/image/whiteImg.png'
export default {
    components: {
        register,
        login,
    },
    props:{
        title:{
            type:String,
            default:'项目申报'
        },
        type:{
            type:String,
            default:'page',
        }
    },
    data() {
        return {
            activeOption: 0, //当前选中
            selectValue: "", //搜索
            navData: [], //导航栏数据
            logoUrl: "",
            isLogin: false, //是否显示登录按钮
            isToken: false, //是否已登录
            reset: false, //重置密码
            isCheck:false,
            isCompanyUser:false,//是否企业用户
            isUser:false,//是否普通用户
        };
    },
    created() {
        this.loadGetData();
        this.loadGetLogin();
        this.getRoleInfo();
        this.logoUrl=whiteImg;
        setTimeout(() => {
            let baseinfo = JSON.parse(localStorage.getItem("baseInfo_key"));
            this.logoUrl =window.location.protocol+'//'+window.location.host+'/website/api'+baseinfo.siteLogo;
            console.log('图标：',this.logoUrl);
        }, 500);
        
    },
    methods: {
        getRoleInfo(){
            let currentRole=localStorage.getItem('userRole_key');
            if(currentRole==roleList.companyCheck){
                this.isCheck=true;
            }
            if(currentRole==roleList.companyUser){
                this.isCompanyUser=true;
            }
            if(currentRole==roleList.user){
                this.isUser=true;
            }
        },
        chagneVisble(type){
            if(type){
                this.getRoleInfo();
            }
        },
        //打开用户中心
        clickOpenUser() {
            this.$emit("userInfo");
        },
        handleCommand(type){
            if(type==1){
                // this.clickOpenUser();
                this.$router.push({path:'/userInfo'})
            }else if(type==2){
                this.clickresetPwd();
            }else if(type==4){
                this.$router.push({path:'/companyList'})
            }else if(type==5){
                this.$router.push({path:'/adminApplyList'})
            }else{
                this.clickQuitLogin();
            }
        },
        //退出登录
        clickQuitLogin() {
            this.$confirm("确定退出登录吗", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                removeToken();
                this.isToken = false;
                localStorage.removeItem('userRole_key');
                this.$router.push({path:'/'});
                
            });
        },
        //点击重置密码
        clickresetPwd() {
            this.reset = true;
            this.$refs.userLogin.show = true;
        },
        //是否登录、注册
        async loadGetLogin() {
            let res = await getLoginSwitch();
            if (res.code == 200) {
                this.isLogin = res.data == "1" ? true : false;

                if (isToken()) {
                    this.isToken = true;
                }
            }
        },
        //获取导航栏数据
        async loadGetData() {
            let res = await getNavData();
            if (res.code == 200) {
                this.navData = res.data;
                this.navData.push({
                    indexId:-1,
                    name:'项目申报',
                    navigationBarList:null
                })
            }
        },
        clickOpenLogin() {
            this.reset = false;
            this.$refs.userLogin.show = true;
        },
        //跳转外部链接
        clickNavUrl(){
             window.open('https://dfmall.sicpay.com', "_blank");
        },
        //登录成功
        successLogin() {
            this.loadGetLogin();
        },
        //点击注册
        clickOpenRegister() {
            this.$refs.userRegister.show = true;
        },
        //点击logo
        clickLogo() {
            this.activeOption = 0;
            if (this.$route.path == "/") {
                this.$emit("home");
            } else {
                this.$router.push({ path: "/" });
            }
        },
        //点击分类子集
        clickItem(id, val) {
            this.navData.forEach((item, index) => {
                if (item.navigationBarList) {
                    item.navigationBarList.forEach((item1) => {
                        if (item1.id == id) {
                            this.activeOption = index;
                        }
                    });
                }
            });
            this.$emit("item", val);
        },
        clickItemOption(key, item) {
            this.activeOption = key;
            this.$emit("item", item);
        },
        //鼠标移入
        mouseOver(key) {
            if (this.navData[key].navigationBarList) {
                this.$refs.xuanting[key].style.display = " block";
            }
        },
        //鼠标移除
        mouseLeave(key) {
            if (this.navData[key].navigationBarList) {
                this.$refs.xuanting[key].style.display = " none";
            }
        },
    },
};
</script>

<style lang="less" scoped>
.topPage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 0.8rem;
    padding: 0 0.8rem;
    z-index: 20;
    background-color: #fff;
    box-shadow: 0 -0.15rem 0.2rem 0.01rem gray;
    .leftIcon {
        height: 0.39rem;
        width: 2.77rem;
        background-size: 100% 100%;
    }
    .queryIcon {
        height: 0.32rem;
        width: 0.32rem;
    }
    .centerOptions {
        display: flex;
        position: relative;
        .item {
            position: relative;
            padding: 0 0.44rem;
        }
        .itemTxt {
            display: inline-block;
            height: 0.8rem;
            line-height: 0.8rem;
            white-space: nowrap;
        }
        .itemTxt:hover {
            color: var(--themeColor);
            
        }
        .active {
            position: absolute;
            left: 50%;
            bottom: 0.02rem;
            transform: translateX(-50%);
            width: 0.5rem;
            height: 0.04rem;
            border-radius: 0.04rem;
        }
        .item:hover {
            cursor: pointer;
        }

        .select {
            display: none;
            width: 2rem;
            padding: 0 0.15rem;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            background: #fff;
            box-shadow: 0.02rem 0.02rem 0.12rem 1px #ddd;
            .options {
                padding: 0.15rem 0;
                border-bottom: 1px solid #dddddd;
                font-size: 0.18rem;
                text-align: center;
            }
            .options:last-child {
                border-bottom: none;
            }
            .options:hover {
                color: var(--themeColor);
                // border-bottom: 1px solid var(--themeColor);
            }
        }
    }
}
.el-card {
    border: none !important;
}
.el-card.is-always-shadow {
    box-shadow: 0 0 0 0 !important;
}
.closeIcon {
    height: 0.2rem;
    width: 0.2rem;
    margin-right: 0.15rem;
}
.cardContent {
    display: none;
    position: fixed;
    left: 0;
    top: 0.8rem;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
}
.topInfo {
    height: 5.6rem;
    background-color: #fff;
    .cardTop {
        display: flex;
        justify-content: center;
        .card-header {
            width: 70%;
            display: flex;
            // justify-content: space-around;
            // margin-right: .6rem;
            .typeList {
                display: inline-block;
                width: 2rem;
                text-align: left;
                margin-right: 0.6rem;
            }
        }
        .card-typelist {
            width: 70%;
            display: flex;
            // justify-content: space-around;
            // margin-right: .6rem;
            .contentList {
                width: 2rem;
                display: flex;
                flex-direction: column;
                margin-right: 0.6rem;
                align-items: flex-start !important;
                .contentItem {
                    margin-bottom: 0.1rem;
                    text-align: left;
                    color: #4b6187;
                }
                .contentItem:hover {
                    color: var(--themeColor);
                }
            }
        }
    }
}
.userImg {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    margin: 0 0.2rem;
}
.setColor:hover{
     background-color: var(--themeColor) !important;
     color: #fff;
}
</style>
