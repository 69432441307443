<template>
    <div class="imgTxt">
        <img src="@/assets/image/banner.png">
        <div class="txtContent">
            <h2 class="txtTitle">公司简介</h2>
            <p class="txtDesc">广州鹏聚信息服务有限公司设立于2015年，汇聚业内顶尖人才，面向国内中小企业，提供线上及线下全方位量身定制的SAAS解决方案
                与支付解决方案。致力于提供专业的技术服务，成为国内技术服务的佼佼者，与各企业一同成长。</p>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.imgTxt {
    width: 100%;
    height: 4.6rem;
    position: relative;
    img {
        height: 100%;
        width: 100%;
    }
    .txtContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.6);
        color: #fff;
        .txtTitle {
            margin-bottom: 0.24rem;
            font-size: 0.45rem;
            font-weight: 600;
            color: #ffffff;
        }
        .txtDesc{
          width: 12rem;
          text-align: center;
          font-size: .18rem;
          line-height: .3rem;
          color: #ffffff;
        }
    }
}
</style>