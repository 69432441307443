<template>
    <div class="caseList">
        <div class="case">
           <modules-title :title="title.name" :detail="title.slogan"></modules-title>
            <div class="caseContent">
                <div class="left">
                    <div class="caseItem cursor" :class="index==activeImg?'theme_border':''" v-for="(item,index) in data" :key="item" @click="clickCaseList(index)">
                        <img class="listLeft" :src="baseUrl + item.icon">
                        <div class="listRight">
                            <h4 class="listTitle" :class="index==activeImg?'theme_color':''">{{item.title}}</h4>
                            <span class="listTxt">{{item.content}}</span>
                        </div>
                        <div class="linkBottom"></div>
                    </div>
                </div>
                <div class="right">
                    <img :src="baseUrl + data[activeImg].url">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import modulesTitle from "@/components/modulesTitle/index";
export default {
    components: {
        modulesTitle,
    },
     props:{
        data:Array,
        title:Object,
    },
    data() {
        return {
            activeImg:0,//当前选中
            baseUrl: process.env.VUE_APP_BASE_API
        };
    },
    methods:{
        clickCaseList(key){
            this.activeImg=key
        }
    }
};
</script>

<style lang="less" scoped>
.caseList {
    text-align: center;
}
.case {
    display: inline-block;
    .caseContent {
        display: flex;
        box-shadow: 0px 2px 20px 0px rgba(1, 78, 181, 0.19);
        border-radius: .15rem;
        .left {
            width: 4.2rem;
            border-radius: .15rem 0 0 .15rem;
            .caseItem {
                display: flex;
                align-items: center;
                padding: .2rem 0.25rem .3rem 0.25rem;
                background: #fff;
                border-bottom: 0.04rem solid #f5f8fc;
                .listLeft {
                    width: 0.34rem;
                }
                .listRight {
                    margin-left: 0.2rem;
                    text-align: left;
                    .listTitle {
                        font-size: 0.18rem;
                        font-weight: 600;
                        color: #333e51;
                        margin-bottom: 0.1rem;
                    }
                    .listTxt {
                        font-size: .14rem;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }
                }
            }
            .caseItem:hover .listRight .listTitle{
                color: var(--themeColor);
            }
            .caseItem:first-child{
                border-top-left-radius: .15rem;
            }
            .caseItem:last-child{
                border-bottom-left-radius: .15rem;
            }
        }
        .right {
            display: flex;
            align-items: center;
            width: 9.8rem;
            border-radius: 0 0.15rem 0.15rem 0;
            background: #f5f8fc;
            img {
                width: 7.9rem;
                margin-left:.25rem
            }
        }
    }
}
</style>
