import Vue from 'vue'
import App from './App.vue'
import router from './router'
import modulesTitle from '@/components/modulesTitle/index'
import topOptions from '@/components/common/topOptions'
import Element from 'element-ui'
import plugins from './plugins' // plugins
import 'element-ui/lib/theme-chalk/index.css'
import utils from '@/utils/utils';
Vue.prototype.$utils=utils;
Vue.use(Element)
Vue.use(plugins)
Vue.config.productionTip = false
Vue.component('modules-title', modulesTitle)
Vue.component('top-options',topOptions)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
