import axios from "axios";
import {getToken} from '@/utils/token'
import { Message,Loading } from 'element-ui';
import {blobValidate} from './ruoyi'
import saveAs from 'file-saver'

var requestHOST=window.location.protocol+'//'+window.location.host+'/website/api';
if(process.env.NODE_ENV=='development'){
    requestHOST=process.env.VUE_APP_BASE_API ;
}

axios.defaults.baseURL =requestHOST  //正式
// axios.defaults.baseURL =process.env.VUE_APP_BASE_API  //正式


//post请求头
axios.defaults.headers.post["Content-Type"] ="application/json";
//设置超时
axios.defaults.timeout = 10000;

axios.interceptors.request.use(
  config => {
    const isToken = (config.headers || {}).isToken === false; //如果头部的isToken==false
    if (getToken() && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
//响应拦截
axios.interceptors.response.use(
  response => {
    // 二进制数据则直接返回
    if(response.request .responseType ===  'blob'){
      return response
    }
    if (response.data.code == 200) {
      return Promise.resolve(response);
    } else {
      Message.error(response.data.msg)
      return Promise.reject(response);
    }
  },
  error => {
  	Message.error(`异常请求：服务器错误`)
  }
);
export default {
  post(url, data,headers={}) {
    return new Promise((resolve, reject) => {
        
      axios({
          method: 'post',
          url,
          // data: qs.stringify(data),
          data
        })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        });
    })
  },

  get(url, data,headers={}) {
    return new Promise((resolve, reject) => {
      axios({
          method: 'get',
          url,
          params: data,
        })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
};
// 通用下载方法
export function download(url, params, filename) {
  const loadingVal = Loading.service({
    lock: true,
    text: "正在下载数据，请稍候",
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  });
  return axios({
    method: 'post',
    url,
    data:params,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'blob'
  }).then(async (res) => {
    const isLogin = await blobValidate(res.data);
    if (isLogin) {
      const blob = new Blob([res.data]);
      saveAs(blob, filename)
    } else {
      const resText = await res.data.text();
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      Message.error(errMsg);
    }
    loadingVal.close();
  }).catch((r) => {
    console.error(r)
    Message.error('下载文件出现错误，请联系管理员！')
    loadingVal.close();
  })
}

