<script>
import draggable from "vuedraggable";
import render from "@/utils/form/render";

const components = {
    itemBtns(h, element, index, parent) {
        const { copyItem, deleteItem } = this.$listeners;
        return [
        ];
    },
};
const layouts = {
    colFormItem(h, element, index, parent) {
        const { activeItem } = this.$listeners;
        let className = "drawing-item noFloat";
        if (this.formConf.unFocusedComponentBorder)
            className += " unfocus-bordered";
        return (
            <el-col
                span={element.span}
                class={className}
                // nativeOnClick={(event) => {
                //     activeItem(element);
                //     event.stopPropagation();
                // }}
            >
                <el-form-item
                    label-width={
                        element.labelWidth ? `${element.labelWidth}px` : null
                    }
                    label={element.label}
                    required={element.required}
                >
                    <render
                        key={element.renderKey}
                        conf={element}
                        onInput={(event) => {
                            this.$set(element, "defaultValue", event);
                        }}
                    />
                </el-form-item>
                {components.itemBtns.apply(this, arguments)}
            </el-col>
        );
    },
    rowFormItem(h, element, index, parent) {
        const { activeItem } = this.$listeners;
        const className = "drawing-row-item noFloat";
        let child = renderChildren.apply(this, arguments);
        if (element.type === "flex") {
            child = (
                <el-row
                    type={element.type}
                    justify={element.justify}
                    align={element.align}
                >
                    {child}
                </el-row>
            );
        }
        return (
            <el-col span={element.span}>
                <el-row
                    gutter={element.gutter}
                    class={className}
                    // nativeOnClick={(event) => {
                    //     activeItem(element);
                    //     event.stopPropagation();
                    // }}
                >
                    <span class="component-name">{element.componentName}</span>
                    <draggable
                        list={element.children}
                        animation={340}
                        group="componentsGroup"
                        class="drag-wrapper"
                    >
                        {child}
                    </draggable>
                    {components.itemBtns.apply(this, arguments)}
                </el-row>
            </el-col>
        );
    },
};

function renderChildren(h, element, index, parent) {
    if (!Array.isArray(element.children)) return null;
    return element.children.map((el, i) => {
        const layout = layouts[el.layout];
        if (layout) {
            return layout.call(this, h, el, i, element.children);
        }
        return layoutIsNotFound();
    });
}

function layoutIsNotFound() {
    throw new Error(`没有与${this.element.layout}匹配的layout`);
}

export default {
    components: {
        render,
        draggable,
    },
    props: ["element", "index", "drawingList", "formConf"],
    render(h) {
        const layout = layouts[this.element.layout];

        if (layout) {
            return layout.call(
                this,
                h,
                this.element,
                this.index,
                this.drawingList
            );
        }
        return layoutIsNotFound();
    },
};
</script>
