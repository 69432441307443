<template>
    <div class="faq pagePadding">
        <modules-title :title="title.name" :detail="title.slogan"></modules-title>
        <div class="query">
            <div class="queryInput">
                <el-input class="inputTxt" v-model="queryForm.name" type="text" placeholder="输入标题搜索"></el-input>
                <el-button class="queryBtn theme_bg" type="primary" @click="clickQuery">搜 索</el-button>
            </div>
        </div>
        <div class="content">
            <div class="leftType">
                <h3 class="fontColor">类别</h3>
                <el-radio-group v-model="queryForm.secondTypeId" @change="chagneRadio" class="radioList">
                    <el-radio  :label="item.id" v-for="(item,index) in typeList" :key="index">{{item.name}}</el-radio>
                </el-radio-group>
            </div>
            <div class="rightList" v-if="faqList.length>0">
                <div class="txtItem cursor" v-for="(item,index) in faqList" :key="index" @click="clickListItem(item)">
                    <h2 class="font22">{{item.title}}</h2>
                </div>

            </div>
            <div class="noData" v-else>暂无数据</div>
        </div>
        <div class="pageCode">
            <el-pagination :background="true" layout="total, sizes, prev, pager, next, jumper" :total="totalData" :page-size="queryForm.pageSize" 
            @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
    </div>
</template>

<script>
import { postcouContentList, postContentTypeList } from "@/api/home";
export default {
    props: {
        title: Object,
    },
    data() {
        return {
            typeList: [], //分类列表
            faqList: [], //数据列表
            totalData: 0,
            queryForm: {
                pageNum: 1,
                pageSize: 10,
                typeId: null,
                secondTypeId: null,
                name: "",
            },
        };
    },
    created() {
        this.queryForm.typeId = this.title.content;
        this.loadGetTypeList();
    },
    methods: {
        async loadGetTypeList() {
            let queryParam = {
                pageNum: 1,
                pageSize: 999,
                parentId: this.title.content,
            };
            let res = await postContentTypeList(queryParam);
            if (res.code != 200) {
                return;
            }
            this.typeList = res.data.records;
            this.queryForm.secondTypeId = this.typeList[0].id;
            this.loadGetDataList();
        },
        async loadGetDataList() {
            let res = await postcouContentList(this.queryForm);
            if (res.code != 200) {
                return;
            }
            this.totalData = res.data.total;
            this.faqList = res.data.records;
        },
        //切换分类
        chagneRadio() {
            this.loadGetDataList();
        },
        //查询
        clickQuery() {
            this.queryForm.pageNum = 1;
            this.loadGetDataList();
        },
        clickListItem(item) {
            this.$emit("itemNav", { data: item, type: "faq" });
        },
        handleSizeChange(val) {
            this.queryForm.pageSize = val;
        },
        handleCurrentChange(val) {
            this.queryForm.pageNum = val;
            this.loadGetList();
        },
    },
};
</script>

<style lang="less" scoped>
/deep/.el-radio-button__inner, .el-radio-group{
    display: block;
}
/deep/.el-radio{
    margin-bottom: .1rem;

}
/deep/.el-radio__label{
    font-size: .18rem !important;

}
/deep/.el-radio__input.is-checked+.el-radio__label{
    color: var(--themeColor) !important;
}
/deep/.el-radio__input.is-checked .el-radio__inner{
    background-color: var(--themeColor) !important;
}

.radioList {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
}
.faq {
    margin-top: .5rem;
    text-align: center;
    .query {
        margin-bottom: 0.5rem;
        text-align: center;
        .queryInput {
            display: flex;
            justify-content: center;
            .inputTxt {
                width: 5rem !important;
                margin-right: 0.1rem;
            }
        }
    }
    .content {
        display: inline-flex;

        .leftType {
            h3 {
                width: 2.6rem;
                margin-bottom: 0.13rem;
                padding-bottom: 0.13rem;
                text-align: left;
                font-weight: 700;
                border-bottom: 1px solid #bfcbdf;
            }
        }
        .rightList {
            width: 10.2rem;
            margin-left: 1.2rem;
            .txtItem {
                width: 100%;
                margin-bottom: 0.28rem;
                padding-bottom: 0.28rem;
                border-bottom: 1px solid #bfcbdf;
                h2 {
                    // margin-bottom: .24rem;
                    text-align: left;
                    font-size: 0.24rem;
                    font-weight: 500;
                }
                h2:hover {
                    color: var(--themeColor);
                }
                p {
                    width: 9rem;
                    text-align: left;
                    font-size: 0.18rem;
                    line-height: 0.34rem;
                }
            }
        }
        .noData {
            width: 10.2rem;
            text-align: center;
            font-size: 0.4rem;
            color: #c1c1c1;
            line-height: 2rem;
        }
    }
}
.queryBtn{
    font-size: .16rem;
    width: .85rem;
    padding: 0;
}
/* 选中后的字体颜色 */
.el-radio__input.is-checked + .el-radio__label {
    color: var(--themeColor) !important;
}
/* 选中后小圆点的颜色 */
.el-radio__input.is-checked .el-radio__inner {
    background: var(--themeColor) !important;
    border-color: var(--themeColor) !important;
}

</style>