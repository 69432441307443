<template>
  <div class="videopage">
      <modules-title title="服务购买" detail="购买服务详情"></modules-title>
      <img class="priceImg" src="@/assets/image/jiadun.jpg" >
      <div class="goBtn">
          <el-button type="primary">
              https://www.cfca.com.cn/lxq/
              <el-icon class="el-icon--right el-icon-arrow-right"></el-icon>
          </el-button>
      </div>
  </div>
</template>

<script>
import modulesTitle from "@/components/modulesTitle/index";
export default {
    components: {
        modulesTitle
    },
    props:{
        videoData:Object
    },
    data(){

    }
}
</script>

<style lang="less">
.videopage{
    padding: 0 2.6rem;
    .priceImg{
        width: 14rem;
        height: 7.4rem;
    }
    .goBtn{
        margin-top: 1rem;
        text-align: center;
    }
}

</style>