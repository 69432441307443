<template>
    <div class="titleBody titleBottom">
        <div class="content fontColor">
            <span class="title">{{title}}</span>
            <span class="border theme_bg"></span>
            <span class="desc">{{detail}}</span>
            <span v-if="detail1" class="desc">{{detail1}}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        detail: String,
        detail1: String,
    },
};
</script>

<style lang="less" scoped>
.titleBody {
    text-align: center;
}
.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    span{
        margin-bottom: .15rem;
    }
}
.title {
    white-space: nowrap;
    font-size: .4rem;
    font-weight: 600;
    line-height: .56rem;
}
.border {
    width: 0.6rem;
    height: 0.04rem;
    border-radius: 0.02rem;
}
.desc {
    white-space: nowrap;
    font-size: .18rem;
    line-height: .26rem;
}
</style>