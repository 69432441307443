<template>
    <div class="newsList pagePadding">
        <modules-title :title="title.name" :detail="title.slogan"></modules-title>
        <div class="listContent">
            <div class="newsItem cursor" v-for="(item,index) in data" :key="index">
                <div class="newsBg">{{item.title}}</div>

                <img class="iconImg" :src="baseUrl+item.url" />
                <p class="newsTxt">{{item.content}} </p>
                <el-button type="primary" plain class="itemBtn" @click="clickNavDetail(item)">{{item.btnTxt}}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Array,
        title: Object,
    },
    data() {
        return {
            baseUrl: process.env.VUE_APP_BASE_API,
        };
    },
    methods: {
        clickNavDetail(item) {
            this.$emit("navUrl", item);
        },
    },
};
</script>
<style scoped lang="less">
.newsList{
    padding-bottom: .2rem;
}
.listContent {
    display: flex;
    flex-wrap: wrap;
    .newsItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 4.28rem;
        height: 5.1rem;
        margin-bottom: 0.6rem;
        margin-right: 0.3rem;
        background: #fff;
        box-shadow: 0.04rem 0.04rem 0.18rem 0px #dddddd;
        border-radius: 0 0 0.08rem 0.08rem;
        .newsBg {
            width: 100%;
            height: 1.92rem;
            text-align: center;
            line-height: 1.3rem;
            font-size: .24rem;
            font-weight: 500;
            color: #ffffff;
            background: linear-gradient(135deg, #1563cb 0%, #212857 100%);
            border-radius: 0.08rem 0.08rem 0px 0px;
        }
        .iconImg {
            height: 1.44rem;
            width: 1.44rem;
            margin-top: -0.72rem;
            border-radius: 50%;
        }
        .newsTxt {
            height: 1.46rem;
            margin-top: 0.28rem;
            padding: 0 0.4rem;
            font-size: 0.18rem;
            font-weight: 500;
            color: #666666;
        }
        .itemBtn {
            width: 1.2rem;
            height: 0.41rem;
            padding: 0;
            font-size: .16rem;
            line-height: 0.41rem;
        }
    }
    .newsItem:nth-child(3n) {
        margin-right: 0 !important;
    }
    .newsItem:nth-child(n+3){
        margin-bottom: .2rem;
    }
}
</style>