<template>
    <div class="pagePadding">
        <modules-title :title="title.name" :detail="title.slogan"></modules-title>
        <el-tabs v-model="queryForm.secondTypeId" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane v-for="(item,index) in typeList" :key="index" :label="item.name" :name="item.id.toString()"></el-tab-pane>
        </el-tabs>
        <div class="listData">
            <div class="dataItem cursor" v-for="(item,index) in faqList" :key="index" @click="clickListItem(item)">
                <img :src="baseUrl+item.image">
                <div class="rightInfo">
                    <p>{{item.title}}</p>
                    <span class="date">{{item.createTime}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { postcouContentList, postContentTypeList } from "@/api/home";
export default {
    props: {
        data: Array,
        title: Object,
    },
    props: {
        title: Object,
    },
    data() {
        return {
            typeList: [], //分类列表
            faqList: [], //数据列表
            totalData: 0,
            queryForm: {
                pageNum: 1,
                pageSize: 6,
                typeId: null,
                secondTypeId: null,
                name: "",
            },
            baseUrl: process.env.VUE_APP_BASE_API,
            activeName: "first",
        };
    },
    created() {
        this.queryForm.typeId = this.title.content;
        this.loadGetTypeList(true);
    },
    methods: {
        async loadGetTypeList(isType) {
            let queryParam = {
                pageNum: 1,
                pageSize: 999,
                parentId: this.title.content,
            };
            let res = await postContentTypeList(queryParam);
            if (res.code != 200) {
                return;
            }
            this.typeList = res.data.records;
            if (isType) {
                this.queryForm.secondTypeId = this.typeList[0].id.toString();
            }
            this.loadGetDataList();
        },
        async loadGetDataList() {
            let res = await postcouContentList(this.queryForm);
            if (res.code != 200) {
                return;
            }
            this.totalData = res.data.total;
            this.faqList = res.data.records;
        },
        clickListItem(item) {
            this.$emit("itemNav", { data: item, type: "faq" });
        },
        //切换tab
        handleClick() {

            this.loadGetTypeList(false);
        },
    },
};
</script>

<style scoped lang="less">
.listData {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0.44rem;
    .dataItem {
        display: flex;
        align-items: center;
        width: 6.8rem;
        height: 1.6rem;
        border: 1px solid #cdd7e6;
        margin-bottom: 0.35rem;
        padding-left: .1rem;
        border-radius: 0.1rem;
        img {
            height: 1.4rem;
            width: 1.98rem;
            margin-right: 0.2rem;
            border-radius: 0.05rem;
        }
        .rightInfo {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding: 0.2rem 0 0.1rem 0;
            p {
                font-size: 0.2rem;
                font-weight: 500;
                color: #333e51;
            }
            span {
                line-height: 0.36rem;
                font-size: 0.16rem;
                font-weight: 400;
                color: #4b6187;
            }
        }
    }
}
/deep/.el-tabs__item {
    font-size: 0.2rem;
}
/deep/.is-active{
    color: var(--themeColor) !important;

}
/deep/.el-tabs__item:hover{
    color: var(--themeColor) !important;

}
/deep/.el-tabs__active-bar{
       background-color: var(--themeColor) !important;

}

</style>