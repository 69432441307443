<template>
    <div class="servePage">
        <modules-title :title="title.name" :detail="title.slogan"></modules-title>
        <div class="flow-content">
            <div class="content-item" v-for="(item,index) in data" :key="item">
                <div class="item-hr cursor">
                    <div class="imgDivBorder">
                        <div class="imgDiv" :class="activeItem==index?'theme_bg':'grayBg'">
                            <img class="stepItemImg" :src="baseUrl + item.icon" @click="clickServerImg(index)">
                        </div>
                    </div>
                </div>
                <div class="itme-text" :class="activeItem==index?'theme_color':''">{{item.title}}</div>
            </div>
        </div>

        <div class="contentInfo">
            <div class="leftImg">
                <img :src="baseUrl + data[activeItem].url">
            </div>
            <div class="rightTxt">
                <h4>{{data[activeItem].title}}</h4>
                <p class="txt">{{data[activeItem].content}}</p>
                <!-- <div class="iconList">
                    <img src="@/assets/image/companyNameIcon.png" alt="">
                    <img src="@/assets/image/companyNameIcon.png" alt="">
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import modulesTitle from "@/components/modulesTitle/index";
export default {
    components: {
        modulesTitle,
    },
    props: {
        data: Array,
        title: Object,
    },
    data() {
        return {
            activeItem: 0, //当前选中的服务
            baseUrl: process.env.VUE_APP_BASE_API,
        };
    },
    created() {},
    methods: {
        //切换服务类型
        clickServerImg(key) {
            if (key != this.activeItem) {
                this.activeItem = key;
            }
        },
    },
};
</script>

<style lang="less">
.servePage {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.flow-content {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content-item {
    width: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.itme-text {
    width: 100%;
    margin-top: 0.1rem;
    text-align: center;
    color: #333;
    font-size: 0.16rem;
    white-space: nowrap;
    // letter-spacing: 1px;
}
.item-hr {
    width: 100%;
    height: 0.03rem;
    background: #bfcbdf;
    margin: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .imgDivBorder {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0.52rem;
        width: 0.52rem;
        background-color: rgba(139,162,199, .32);
        border-radius: 50%;
        .imgDiv {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 0.42rem;
            width: 0.42rem;
            opacity: 1;
            border-radius: 50%;
            .stepItemImg {
                height: 0.3rem;
                width: 0.3rem;
            }
        }
    }
}

.contentInfo {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    .leftImg {
        height: 4rem;
        width: 6.2rem;
        margin-right: 0.8rem;
        img {
            width: 100%;
            height: 100%;
            border-radius: 0.1rem;
        }
    }
    .rightTxt {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;

        height: 4rem;
        padding: 0.3rem 0;
        h4 {
            margin-bottom: 0.3rem;
            font-size: 0.26rem;
            font-weight: 500;
            color: #333e51;
            line-height: 0.37rem;
        }
        .txt {
            width: 7rem;
            line-height: 0.35rem;
            font-size: 0.16rem;
        }
        .iconList {
            img {
                width: 2rem;
                margin-right: 0.1rem;
            }
        }
    }
}
.grayBg {
    background: #6F85AC;
}
</style>
