import {getCode} from '@/api/home'
export default {
    isHeightShow() {
        let current=document.documentElement.style.fontSize.substring(0,document.documentElement.style.fontSize.length-2);
        console.log('计算后字体：',current);
        let htmlHeight = document.documentElement.clientHeight - Number(current*3.46);//html高度减去底部栏高度
        let bodyHeight = document.body.clientHeight;
        console.log('hetml，body：',htmlHeight,bodyHeight)
        if (htmlHeight < bodyHeight) {
            return true;
        } else {
            return false;
        }
    },
    checkPhone(phone) {
        if (phone.toString().length == 11) {
            if (!(/^1[3456789]\d{9}$/.test(phone))) {
                return false;
            } else {
                return true;
            }
        }
    },
    //判断表单是否未填
    checkFormNull(formData){
        let isSuccess=true;
        formData.forEach((item) => {
            if (item.required) {
                if (!item.defaultValue || item.defaultValue.length == 0) {
                    console.log("未输入", item);
                    isSuccess = false;
                }
            }
        });
        console.log('是否未填：',isSuccess);
        return isSuccess;
    },
    
}