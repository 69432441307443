<template>
    <div class="domain">
            <modules-title :title="title.name" :detail="title.slogan"></modules-title>
            <div class="functionList">
                <div class="leftList">
                    <div :class="activeFunction==index?'activeleftList':''" :style="{background:activeFunction==index?item.isFontColor:item.noFontColor}" 
                    class="itemList cursor" v-for="(item,index) in data" :key="index" @click="clickItem(index)">
                        <img :src="baseUrl + item.icon" alt="">
                        <div class="itemTxt">
                            <h4  :style="{color:activeFunction==index?item.fontColor:''}">{{item.title}}</h4>
                            <span class="duohang3" v-if="index==activeFunction" :style="{color:activeFunction==index?item.fontColor:''}">{{item.content}}</span>
                        </div>
                    </div>
                </div>
                <img class="rightImg" :src="baseUrl + data[activeFunction].url" @click="$emit('navUrl',data[activeFunction])">
            </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Array,
        title: Object,
    },
    data() {
        return {
            activeFunction: 0,
            baseUrl: process.env.VUE_APP_BASE_API
        };
    },
    methods: {
        clickItem(index){
            this.activeFunction=index;
        }
    },
};
</script>

<style lang="less" scoped>
.domain {
    padding: 0 2.6rem;
        .functionList {
            display: flex;
            align-items: center;
            .rightImg {
                height: 5.6rem;
                width: 8.9rem;
                margin-left: 1rem;
            }
            .leftList {
                width: 4rem;
                .desc {
                    text-align: left;
                    margin-bottom: 0.3rem;
                    h3 {
                        margin-bottom: .13rem;
                        font-size: 0.24rem;
                    }
                    span{
                        font-size: .16rem;
                    }
                }
                .itemList {
                    display: flex;
                    align-items: center;
                    height: 0.8rem;
                    padding: 0 0.13rem;
                    background: #e7effd;
                    border-radius: 0.08rem;
                    margin-bottom: 0.1rem;
                    img {
                        height: 0.6rem;
                        width: 0.6rem;
                        margin-right: 0.1rem;
                    }
                    .itemTxt {
                        text-align: left;
                        h4 {
                            color: #fff;
                            font-size: 0.18rem;
                            margin-bottom: 0.15rem;
                        }
                        span {
                            color: #e7effd;
                            font-size: 0.14rem;
                        }
                    }
                }
                .activeleftList {
                    height: 1.4rem;
                    background: #014eb5;
                }
            }
        }

}

</style>
