<template>
    <router-view />
</template>
<script>
import { companyInfo } from "@/api/home";
export default {
    async created() {
        let res = await companyInfo();
        if (res.code == 200) {
            localStorage.setItem(
                "baseInfo_key",
                JSON.stringify(res.data.baseInfoBody)
            );
            localStorage.setItem(
                "bottomInfo_key",
                JSON.stringify(res.data.bottomBody)
            );
            localStorage.setItem(
                "serverInfo_key",
                JSON.stringify(res.data.cusServiceBody)
            );
            document
                .querySelector("body")
                .style.setProperty(
                    "--themeColor",
                    res.data.baseInfoBody.mainColor
                        ? res.data.baseInfoBody.mainColor
                        : "#3C83FF"
                );
            this.changeFavicon(res.data.baseInfoBody.labelIcon);
            document.title = res.data.baseInfoBody.siteName;
        }
    },
    methods: {
        changeFavicon(link) {
            let $favicon = document.querySelector('link[rel="icon"]');
            if ($favicon !== null) {
                $favicon.href = window.location.protocol+'//'+window.location.host+'/website/api' + link;
            } else {
                $favicon = document.createElement("link");
                $favicon.rel = "icon";
                $favicon.href = link;
                document.head.appendChild($favicon);
            }
        },
    },
};
</script>

<style lang="less">
@import url("@/assets/css/common.css");
.ql-align-center {
    text-align: center;
}
.ql-align-right {
    text-align: right;
}
.ql-align-left {
    text-align: left;
}
 .ql-size-small {
    font-size: 0.75em;
}
 .ql-size-large {
    font-size: 1.5em;
}
 .ql-size-huge {
    font-size: 2.5em;
}
.ql-editor ul {
    padding-left: 1.5em;
}
.ql-indent-1{
    padding-left: 3em;
}
</style>
