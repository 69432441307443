<template>
    <div style="margin-top:.7rem">
        <modules-title :title="pageContent.title"></modules-title>
        <div class="pageContent" v-html="pageContent.content"></div>

        <div class="template">
            <h1>申请流程</h1>
            <fileUpload v-if="pageContent.declareProcess==='0'" @input="uploadFile" :fileType="fileType"></fileUpload>

            <div class="down" v-if="pageContent.declareProcess==='0'">
                <a class="cursor" :href="base+pageContent.declareValue">下载模板</a>
            </div>

            <div v-if="pageContent.declareProcess==='1'">
                <el-form size="medium" label-position="right" label-width="100px">
                    <draggable-item v-for="(element, index) in formData" :key="element.renderKey" :drawing-list="formData" :element="element" :index="index" :form-conf="formConf" />
                </el-form>
            </div>
        </div>
        <div class="btnCenter martop20">
            <el-button type="primary" class="bottomBtn" @click="clicikSubmit">提交申请</el-button>
        </div>
    </div>
</template>

<script>
import fileUpload from "@/components/upload/file";
import { postSubmitApply, getContentDetail, getFormDetail } from "@/api/apply";
import draggableItem from "@/views/home/viewDraggableItem";
import { formConf } from "@/utils/form/formConfig";

export default {
    components: {
        fileUpload,
        draggableItem,
    },
    props: {
        data: Object,
    },
    watch: {
        data(val) {
            this.getContentDetail();
        },
    },
    data() {
        return {
            formConf,
            pageContent: {}, //文章信息
            formData: [], //表单信息
            base: process.env.VUE_APP_BASE_API,
            fileType: ["doc", "txt", "pdf"],
            submitForm: {
                couCenterId: "",
                type: "",
                controls: "",
                fileUrl: "",
                roleId: "",
            },
        };
    },
    created() {
        this.getContentDetail();
    },
    methods: {
        //查询三级文章详情
        async getContentDetail() {
            console.log("加入我们数据：", this.data);
            let res = await getContentDetail(this.data.jumpUrl);
            if (res.code == 200) {
                this.pageContent = res.data;
                if (res.data.declareProcess == 1) {
                    this.getFormDetail(res.data.declareValue);
                }
            }
        },
        //提交申请
        async clicikSubmit() {
            // this.submitForm.type = this.data.roleId; //获取申请类型
            this.submitForm.couCenterId = this.data.jumpUrl; //获取三级内容id
            this.submitForm.roleId = this.data.roleId;
            console.log("表单：", this.submitForm);
            if (this.pageContent.declareProcess == 0) {
                if (!this.submitForm.fileUrl) {
                    this.$message.error("请上传附件");
                    return;
                }
                this.submitForm.controls = "";
                let res = await postSubmitApply(this.submitForm);
                if (res.code == 200) {
                    this.$message({
                        type: "success",
                        message: "已提交申请",
                    });
                }
                return;
            } 

            if (!this.$utils.checkFormNull(this.formData)) {
                this.$message.error("有必填未输入");
                return;
            }
             this.submitForm.controls =JSON.stringify(this.formData);
             this.submitForm.fileUrl = "";
            let res = await postSubmitApply(this.submitForm);
            if (res.code == 200) {
                this.$message({
                    type: "success",
                    message: "已提交申请",
                });
            }
        
        },
        //查询表单
        async getFormDetail(id) {
            let res = await getFormDetail(id);
            if (res.code == 200) {
                this.formData = res.data.controlsJson;
            }
        },
        //文件上传回调
        uploadFile(val) {
            this.submitForm.fileUrl = val;
        },
    },
};
</script>

<style scoped lang="less">
.pageContent {
    margin: 0 auto;
    padding-bottom: 0.2rem;
    width: 14rem;
}
.template {
    width: 14rem;
    margin: 0.3rem auto;
    h1 {
        margin-bottom: 0.2rem;
    }
    .down {
        display: flex;
        align-items: flex-end;
        a {
            margin-top: 0.3rem;
            margin-right: 0.2rem;
            color: #025cec;
        }
    }
}
.bottomBtn {
    margin-top: 0.3rem;
    width: 5.8rem;
}
</style>