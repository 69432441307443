import request from '@/utils/request.js';

//提交申请
export function postSubmitApply(data) {
    return request.post('/pc/userDeclare/submitDeclare',data);
};

//三级分类查询
export function getContentDetail(id) {
    return request.get(`/pc/cou_center/${id}`);
};

//表单详情查询
export function getFormDetail(id) {
    return request.get(`/pc/form/${id}`);
};
//角色查询
export function getUserRole() {
    return request.get(`/pc/role/list`);
};

//企业提交申请
export function postSubmitCompany(data) {
    return request.post('/specialist/enterprise/enterpriseCertification',data);
};
//个人角色认证
export function postSubmitUser(data) {
    return request.post('/pc/user/userCertification',data);
};
