<template>
    <div>
        <div style="height:4.5rem"></div>
        <div class="bottompage">
            <div class="bottomContent">
                <div class="companyInfo">
                    <div class="contact">
                        <h3>联系我们</h3>
                        <span class="zimu">Contact Us</span>
                        <span>联系电话： {{info.mobile}}</span>
                        <span>电子邮件：{{info.email}}</span>
                        <span>联系地址：{{info.contactAddress}}</span>
                        <span>技术支持：{{info.technology}}</span>
                    </div>
                    <div class="icon" v-if="info.companyBottomLogo">
                        <!-- <img class="img1" src="@/assets/image/company0.png" alt=""> -->
                        <img class="img2" :src="baseUrl +info.companyBottomLogo" alt="">
                    </div>
                    <div class="qrCodeList" v-if="info.companyQrCode.length>0">
                        <div class="qrItem" v-for="(item,index) in info.companyQrCode" :key="index">
                            <img v-if="item.qrCode" class="code" :src="baseUrl + item.qrCode" alt="">
                            <span v-if="item.explain">{{item.explain}}</span>
                        </div>
                         
                    </div>
                   
                </div>
                <div class="beian">版权所有 ©2020 广州鹏聚信息服务有限公司 粤公网安备 44010602005549号 粤ICP备19158350号</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            info:{
                companyQrCode:[]
            },//底部数据
            baseUrl:''
        }
    },
    created(){
        this.baseUrl=window.location.protocol+'//'+window.location.host+'/website/api';
        setTimeout(() => {
            this.info=JSON.parse(localStorage.getItem('bottomInfo_key'));
            this.info.companyQrCode=this.info.companyQrCode.split(',');
        }, 500);
        
    }
};
</script>

<style lang="less">
.bottompage {
    width: 100%;
    height: 3.46rem;
    // height: 346px;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0.6rem 0 0.2rem 0;
    background-color: #1a2841;
    display: flex;
    justify-content: center;
    .bottomContent {
        width: 80%;
        .companyInfo {
            display: flex;
            justify-content: space-between;
            color: #fff;
            padding-bottom: 0.4rem;
            border-bottom: 0.01rem solid #4b6187;
            .contact {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                h3 {
                    font-size: 0.2rem;
                    font-weight: 400;
                    color: #ffffff;
                }
                .zimu {
                    margin-bottom: 0.24rem;
                    font-size: 0.16rem;
                    font-weight: 400;
                    color: #4b6187;
                }
                span {
                    color: #b9c3da;
                    margin-bottom: 0.1rem;
                    font-size: 0.18rem;
                }
            }
            .icon {
                margin-top: 0.2rem;
                .img1 {
                    width: 1.97rem;
                    margin-right: 0.15rem;
                }
                .img2 {
                    width: 2.6rem;
                }
            }
            .qrCodeList{
                display: flex;
                align-items:center;
                .qrItem{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-right: .4rem;
                    .code {
                width: 1.5rem;
                height: 1.5rem;
            }
                    span{
                        margin-top: .1rem;
                        color: #b9c3da;
                    }
                }
            }
            
        }
        .beian {
            margin-top: 0.1rem;
            text-align: center;
            font-size: 0.16rem;
            font-weight: 400;
            color: #b9c3da;
        }
    }
}
</style>
