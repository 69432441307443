import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      title: "首頁"
    }
  },
  {
    path: '/activityDesc',
    name: 'ActivityDesc',
    component: ()=>import('@/views/activity/desc.vue'),
    meta: {
      title: "活动详情"
    }
  },
  {
    path: '/activityMyapply',
    name: 'ActivityMyapply',
    component: ()=>import('@/views/activity/myapply.vue'),
    meta: {
      title: "活动申请"
    }
  },
  {
    path: '/activityForm',
    name: 'activityForm',
    component: ()=>import('@/views/activity/activityForm.vue'),
    meta: {
      title: "填写申请表单"
    }
  },
  {
    path: '/adminApplyList',
    name: 'adminApplyList',
    component: ()=>import('@/views/activity/adminApplyList.vue'),
    meta: {
      title: "申请列表"
    }
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    component: ()=>import('@/views/company/userInfo.vue'),
    meta: {
      title: "用户信息"
    }
  },
  {
    path: '/companyList',
    name: 'companyList',
    component: ()=>import('@/views/company/companyList.vue'),
    meta: {
      title: "我的企业"
    }
  },
  {
    path: '/submitSuccess',
    name: 'SubmitSuccess',
    component: ()=>import('@/views/activity/submitSuccess.vue'),
    meta: {
      title: "提交申请"
    }
  },
  {
    path: '/applyCheckDetail',
    name: 'applyCheckDetail',
    component: ()=>import('@/views/activity/applyCheckDetail.vue'),
    meta: {
      title: "申请审批详情"
    }
  },
  {
    path: '/historyCheck',
    name: 'HistoryCheck',
    component: ()=>import('@/views/activity/historyCheck.vue'),
    meta: {
      title: "历史评审"
    }
  },
  {
    path: '/applyComment',
    name: 'applyComment',
    component: ()=>import('@/views/activity/applyComment.vue'),
    meta: {
      title: "评审内容"
    }
  },
  {
    path: '/adminConfirm',
    name: 'adminConfirm',
    component: ()=>import('@/views/activity/adminConfirm.vue'),
    meta: {
      title: "最终确认"
    }
  },
  {
    path: '/expertDist',
    name: 'expertDist',
    component: ()=>import('@/views/activity/expert/expertDist.vue'),
    meta: {
      title: "专家分配"
    }
  },
  {
    path: '/expertList',
    name: 'expertList',
    component: ()=>import('@/views/activity/expert/expertList.vue'),
    meta: {
      title: "专家列表"
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: "/website/pc/",
  routes
})

export default router
