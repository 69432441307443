<template>
    <div class="judicialList">
        <div class="judicialContent">
            <modules-title :title="title.name" :detail="title.slogan"></modules-title>
             <div class="radio">
                <el-radio-group v-model="activeKey" @change="changeRadio">
                    <!-- <el-radio-button :label="item.title" :value="index" v-for="(item,index) in data" :key="index" /> -->
                     <el-radio-button v-for="(item,index) in data" :key="index" :label="index" border>{{item.title}}</el-radio-button>
                </el-radio-group>
            </div>
            <div class="contentImgTxt">
                <img class="listImg" :src="baseUrl+title.imgUrl">
                <div class="txtPostion">
                    <h2>{{data[activeKey].title}}</h2>
                    <p v-if="data[activeKey].contentList">{{data[activeKey].contentList[activeContent]}}</p>
                    <div class="pageNum">
                        <el-icon class="pageIcon cursor" size=".3rem" @click="clckPageNum('upper')">
                            <arrow-left />
                        </el-icon>
                        <span class="num font20">{{activeContent+1}}/{{data[activeKey].contentList.length}}</span>
                        <el-icon class="pageIcon cursor" size=".3rem" @click="clckPageNum('next')">
                            <arrow-right />
                        </el-icon>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import modulesTitle from "@/components/modulesTitle/index";
import { ArrowLeft, ArrowRight } from "element-ui";
export default {
    components: {
        modulesTitle,
        ArrowLeft,
        ArrowRight,
    },
    props: {
        data: Array,
        title: Object,
    },
    data() {
        return {
            activeKey: 0,
            activeContent:0,//当前选中的内容
             baseUrl: process.env.VUE_APP_BASE_API
        };
    },
    methods: {
        clckPageNum(type) {
            if (type == "next") {
                if(this.activeContent+1 < this.data[this.activeKey].contentList.length){
                    this.activeContent++
                }

            } else if (type == "upper") {
                if(this.activeContent>0){
                    this.activeContent--
                }
            }
        },
        //asdf
        changeRadio(val){
            this.activeContent=0;
            console.log(val,this.activeKey)
        },
    },
};
</script>

<style lang="less" scoped>
.judicialList {
    padding: 0 2.6rem;
    .contentImgTxt {
        width: 14rem;
        margin-top: 0.8rem;
        text-align: right;
        position: relative;
        .listImg {
            width: 10.77rem;
            height: 6.4rem;
            margin-bottom: 0.2rem;
        }
        .txtPostion {
            height: 6.83rem;
            width: 6.25rem;
            position: absolute;
            left: 0;
            top: -0.6rem;
            padding: 0.4rem;
            box-sizing: border-box;
            background: rgba(255, 255, 255, 0.85);
            box-shadow: 8px 6px 22px 0px rgba(29, 59, 100, 0.2);
            border-radius: 20px;
            border: 2px solid var(--themeColor);

            h2 {
                margin-bottom: 0.2rem;
                text-align: center;
                font-size: 0.3rem;
            }
            p {
                height:4.8rem;
                font-size: 0.16rem;
                line-height: 0.3rem;
                white-space: pre-wrap;
               overflow: hidden;
               text-align: left;
            }
            .pageNum {
                display: flex;
                align-items: center;
                position: absolute;
                left: 50%;
                bottom: 0.4rem;
                transform: translateX(-50%);
                .pageIcon:hover{
                    color: var(--themeColor);
                }
                .num{
                    margin: 0 .3rem;
                }
            }
        }
    }
    .radio {
        width: 14rem;
        text-align: center;
    }
}

.el-radio-button__inner {
    padding: 0.15rem 0;
    width: 3.5rem;
    font-size: 0.2rem !important;
    font-weight: 500;
    color: #333e51;

}
.el-radio-button__original-radio:checked+.el-radio-button__inner{
    background: var(--themeColor);
    border-color: var(--themeColor);
    box-shadow: -1px 0 0 0 var(--themeColor);
    &:hover {
          color:#fff !important;
        }
}
 .el-radio-button__inner {
        // 鼠标滑过小圆点时的样式
        &:hover {
          color:var(--themeColor) !important;
        }
 }
</style>
