<template>
    <div style="white-space: nowrap;">
        <el-input class="inputCode" :prefix-icon="CircleCheck" :style="{width:width}" v-model="codeVal" type="number" maxlength="6" placeholder="手机验证码" @input="updateCode"></el-input>
        <el-button v-if="isCode" class="codeBtn" type="primary" @click="clickGetCode" >发送验证码</el-button>
        <el-button v-else class="codeBtn">{{codeNum}}s 后重试</el-button>
    </div>
</template>

<script>

import { getCode, getUserRegister } from "@/api/home";
import { CircleCheck } from "element-ui";
import utils from "@/utils/utils";
export default {
    props: {
        phone: String,
        isUser: {
            type: Boolean,
            default: false,
        },
        width: String,
        code: String,
    },
    data() {
        return {
            codeVal: "",
            codeNum: 60, //倒计时
            isCode: true, //是否可获取验证码
            time: null,
            CircleCheck,
        };
    },
    methods: {
        //输入验证码
        updateCode() {
            this.$emit("inputcode", this.codeVal);
        },
        //点击获取验证码
        async clickGetCode() {
            let errtxt;
            if (!this.phone) {
                errtxt = "请输入手机号";
            } else if (!utils.checkPhone(this.phone)) {
                errtxt = "无效的手机号";
            } else if (this.isUser) {
                let res = await getUserRegister(this.phone);
                if (res.code == 200) {
                    res.data ? (errtxt = "手机号已注册") : (errtxt = "");
                }
            }
            if (errtxt) {
                 this.$message.error(errtxt);
                return;
            }
            let res1 = await getCode(this.phone);
            if (res1.code == 200) {
                let that = this;
                if (this.codeNum == 60 && this.isCode == true) {
                    this.codeNum--;
                    this.isCode = false;
                    this.time = setInterval(() => {
                        this.codeNum--;

                        if (this.codeNum == 0) {
                            clearInterval(that.time);
                            this.isCode = true;
                            this.codeNum = 60; //恢复完重回60秒
                        }
                    }, 1000);
                }
            }
        },
    },
};
</script>

<style lang="less" scoped>
.inputCode {
    margin-right: 0.05rem;
}
.codeBtn {
    padding: 0 0.1rem;
    height: 0.36rem;
    line-height: 0.36rem;
    margin-left: 0.1rem;
    font-size: 0.16rem;
}
</style>