<template>
    <div>
        <div class="tool">
            <div class="toolItem cursor" v-for="(item,index) in toolList" :key="index" @click="clickTool(item.id)">
                <img :src="item.img" :style="{height:item.height,width:item.width}" alt="">
                <span class="toolTxt">{{item.name}}</span>
            </div>
        </div>
        <el-dialog width="10.2rem" :visible.sync="dialogTableVisible">
            <div class="contactContent">
                <div class="codeImg">
                    <h5>微信号:</h5>
                    <span class="font16">{{serveData.weChatNo}}</span>
                    <img :src="baseUrl + serveData.weChatQrCode" alt="">
                </div>
                <div class="codeImg">
                    <h5>公众号:</h5>
                    <span class="font16">{{serveData.weChatPublic}}</span>
                    <img :src="baseUrl + serveData.weChatPublicQrCode" alt="">
                </div>
                <div class="codeImg flexLeft">
                    <div>
                        <h5>联系邮箱:</h5>
                        <span class="font16">{{serveData.contactEmail}}</span>
                    </div>
                    <div>
                        <h5>联系地址:</h5>
                        <span class="font16">{{serveData.contactAddress}}</span>
                    </div>

                </div>
            </div>
        </el-dialog>

        <el-dialog width="15.1rem" :visible.sync="autoShow">
            <div class="auto">
                <div class="autoTitle">加入我们</div>
                <div class="dataList">
                    <div v-for="item in autoData" :key="item.id" class="itemContent">
                        <div class="itemTitle">{{item.name}}</div>
                        <img :src="baseUrl +item.logo">
                        <p>{{item.introduction}}</p>
                        <el-button type="primary" @click="clickNavBtn(item)">{{item.content}}</el-button>
                    </div>
                </div>

            </div>
        </el-dialog>
    </div>

</template>

<script>
import serverPng from "@/assets/image/server.png";
import returnTop from "@/assets/image/returnTop.png";
import auto from "@/assets/image/auto.png";
import { getAutoInfo } from "@/api/home";
export default {
    data() {
        return {
            toolList: [
                {
                    id: 0,
                    img: auto,
                    height:'.34rem',
                    width:'.41rem',
                    name: "加入我们",
                },
                {
                    id: 1,
                    img: serverPng,
                     height:'.34rem',
                    width:'.38rem',
                    name: "联系客服",
                },
                {
                    id: 2,
                    img: returnTop,
                     height:'.33rem',
                    width:'.35rem',
                    name: "返回顶部",
                },
            ],
            timer: null,
            dialogTableVisible: false, //联系方式
            autoShow: false,
            serveData: {}, //联系数据
            baseUrl: process.env.VUE_APP_BASE_API,
            autoData: [], //关于我们
        };
    },
    created() {
        this.serveData = JSON.parse(localStorage.getItem("serverInfo_key"));
        this.loadGetAuto();
    },
    methods: {
        async loadGetAuto() {
            let res = await getAutoInfo();
            if (res.code == 200) {
                this.autoData = res.data;
            }
        },
        //加入我们
        clickNavBtn(val) {
            if (val.jumpType == 2) {
                this.$emit("join", val);
            } else {
                this.$emit("navUrl",val);
            }
            this.autoShow=false;
        },
        //点击工具
        clickTool(type) {
            if (type == 0) {
                this.autoShow = true;
            } else if (type == 1) {
                this.dialogTableVisible = true;
            } else if (type == 2) {
                this.goTop();
            }
        },
        //滑动到顶部
        goTop() {
            cancelAnimationFrame(this.timer);
            const self = this;
            self.timer = requestAnimationFrame(function fn() {
                const oTop =
                    document.body.scrollTop ||
                    document.documentElement.scrollTop;
                if (oTop > 0) {
                    document.body.scrollTop =
                        document.documentElement.scrollTop = oTop - 200;
                    self.timer = requestAnimationFrame(fn);
                } else {
                    cancelAnimationFrame(self.timer);
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.tool {
    position: fixed;
    z-index: 999;
    right: 0.3rem;
    bottom: 0.8rem;
    .toolItem {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 0.75rem;
        height: 0.75rem;
        background: #fff;
        box-shadow: 0px 2px 10px 0px rgba(1, 78, 181, 0.16);
        border-radius: 0.1rem;
        margin-bottom: 0.15rem;
        img {
            height: 0.34rem;
            width: 0.38rem;
            margin-bottom: 0.05rem;
        }

        .toolTxt {
            font-size: 0.14rem;
            color: #333e51;
        }
    }
    .toolItem:hover {
        box-shadow: 0px 2px 10px 0px var(--themeColor);
    }
    .toolItem {
        img {
            width: 0.33rem;
            height: 0.33rem;
        }
    }
}
.contactContent {
    display: flex;
    padding-bottom: .5rem;
    .codeImg {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 0.9rem;
        height: 2rem;
        border-right: 1px solid #bfcbdf;
        h5 {
            margin-bottom: 0.1rem;
            font-size: 0.18rem;
        }

        img {
            height: 1.5rem;
            width: 1.5rem;
            margin-top: 0.1rem;
        }
    }
    .flexLeft {
        align-items: flex-start;
    }
    .codeImg:last-child {
        justify-content: space-between;
        border-right: none;
    }
}

.auto {
    padding-bottom: .3rem;
    .autoTitle {
        text-align: center;
        font-weight: 600;
        color: #333e51;
        font-size: 0.4rem;
        margin-bottom: 0.5rem;
    }
    .dataList {
        display: flex;
        align-items: center;
        .itemContent {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 33.3%;
            padding: 0.1rem .72rem;
            box-sizing: border-box;
            border-right: 1px solid #dddddd;
            .itemTitle {
                margin-bottom: 0.7rem;
                font-weight: 500;
                color: #025cec;
                font-size: 0.24rem;
            }
            img {
                width: 1.25rem;
                height: 1.25rem;
                margin-bottom: 0.25rem;
                border-radius: 50%;
            }
            p {
                height: 1rem;
                color: #666;
                font-size: 0.18rem;
                margin-bottom: 0.55rem;

                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
                overflow: hidden;
            }
        }
        .itemContent:last-child {
            border: none;
        }
    }
}

.el-dialog__body {
    padding-left: 0;
    padding-right: 0;
    padding: 0;
}
</style>
