<template>
    <div v-show="isPage" :class="isPosition?'positionRelative':''">
        <!-- <div v-if="isPage" class="positionRelative"> -->
        <top-options type="index" @item="clickNavItem" @home="clickLogo" @userInfo="isUserInfo=true"></top-options>
        <div v-if="!isDetail && !showJoin && !isUserInfo">
            <keep-alive>
                <div>
                    <component v-for="(item,index) in pageComponent" :key="index" :is="item.pageComponentName" :data="item.pageComponentData" :title="item.pageComponentTitle" @navUrl="clickNavUrl" @itemNav="clickNewsItem"></component>
                </div>
            </keep-alive>
        </div>
        <!-- 详情页 -->
        <rich-txt v-if="isDetail  && !showJoin && !isUserInfo" :id="activeDetailId" :type="activeDetailType"></rich-txt>

        <!-- 底部 -->
        <bottom-info></bottom-info>

        <!-- 申请 -->
        <!-- <apply v-if="showJoin" :data="JoinData"></apply> -->

        <!-- 个人中心 -->
        <!-- <userInfo v-if="isUserInfo"></userInfo> -->

        <!-- 右侧工具 -->
        <!-- <page-tool @join="clickJoinRole" @navUrl="clickNavUrl"></page-tool> -->
    </div>
</template>

<script>
import topOptions from "@/components/common/topOptions.vue";
import bannerImg from "@/components/bannerImg/index.vue";
import serviceSupport from "@/components/serviceSupport/index.vue";
import bottomInfo from "@/components/common/bottom.vue";
import caselist from "@/components/caselist/index.vue";
import customer from "@/components/customer/index.vue";
import news from "@/components/news/index.vue";
import newsList from "@/components/newsList/index.vue";
import pageVideo from "@/components/pageVideo/index.vue";
import buyMoney from "@/components/buyMoney/index.vue";
import judicialList from "@/components/judicialList/index.vue";
import domain from "@/components/domain/index.vue";
import topImg from "@/components/topImg/index.vue";
import imgTxt from "@/components/imgTxt/index.vue";
import flowPath from "@/components/flowPath/index.vue";
import pageTool from "@/components/pageTool/index.vue";
import faqList from "@/components/faqList/index.vue";
import autoInfo from "@/components/autoInfo/index.vue";
import goodsTxt from "@/components/goodsTxt/index.vue";
import richTxt from "@/components/richTxt/index.vue";
import goods from "@/components/goods/index.vue";
import apply from "@/components/apply/index";
import join from "@/components/join/index";
import caseVideo from "@/components/case/index";
import userInfo from "@/components/common/userInfo.vue";
import formDetail from "@/components/form/index";
import activityList from "@/components/activityList/index.vue";

import { getHomePageData, getPageData } from "@/api/home.js";
import utils from "@/utils/utils";
export default {
    components: {
        topOptions,
        bannerImg,
        serviceSupport,
        bottomInfo,
        caseList: caselist,
        customerList: customer,
        news,
        newsList,
        pageVideo,
        buyMoney,
        judicialList,
        domain,
        pageTool,
        topImg,
        imgTxt,
        flowPath,
        faqList,
        autoInfo,
        goodsTxt,
        richTxt,
        goods,
        apply,
        join,
        caseVideo,
        userInfo,
        formDetail,
        activityList,
    },
    data() {
        return {
            pageComponent: [], //页面组件
            isPage: false, //页面加载中
            isPosition: false, //是否定位

            JoinData: "", //申请数据
            activePageID: null, //首页
            activeDetailId: "", //详情id
            activeDetailType: "", //详情类型

            isUserInfo: false, //个人信息展示
            isDetail: false, //是否详情
            showJoin: false, //显示加入我们申请
        };
    },
    created() {
        // this.loadGetPageInfo();
        this.getApply();
    },
    // 2.页面销毁时，取消监听，否则其他vue路由页面也会被监听
    unmounted() {
        console.log("销毁页面");
    },
    methods: {
        //获取申请
        getApply(){
            this.isDetail = false;
        this.showJoin = false;
        this.isUserInfo = false;
        this.pageComponent = [
            {
                pageComponentName: this.getTemplateType("-1"),
                pageComponentData: {},
                pageComponentTitle: {},
            },
        ];
        console.log('页面组件：',this.pageComponent);
        this.isPage=true;
        setTimeout(() => {
            this.isPosition = utils.isHeightShow();
        }, 500);
        },
        //跳转
        clickNavUrl(val) {
            console.log("点击跳转：", val);
            //跳转到新闻
            if (val.jumpType == 1) {
                this.openDetailContent(val.jumpUrl, "news");
            } else if (val.jumpType == 2) {
                this.openDetailContent(val.jumpUrl, "faq");
            } else if (val.jumpType == 3) {
                this.tabBarNavTemplate(val.jumpUrl);
            } else if (val.jumpType == 4) {
                if (
                    val.jumpUrl.indexOf("http://") != -1 ||
                    val.jumpUrl.indexOf("https://") != -1
                ) {
                    window.open(val.jumpUrl, "_blank");
                }
            }
        },
        //加入我们
        clickJoinRole(e) {
            console.log("打开申请页面：", e);
            this.JoinData = e;
            this.isDetail = false;
            this.isUserInfo = false;
            this.showJoin = true;
        },
        //点击新闻动态/faq
        clickNewsItem(val) {
            if (val.data == "more") {
                this.activeDetailId = val.data;
            } else {
                this.openDetailContent(val.data.id, val.type);
            }
            this.activePageID = "0";
        },
        //打开新闻详情或者faq
        openDetailContent(id, type) {
            this.isDetail = true;
            this.activeDetailId = id;
            this.activeDetailType = type;
            setTimeout(() => {
                this.isPosition = utils.isHeightShow();
            }, 500);
        },
        //点击顶部导航栏
        clickNavItem(val) {
            this.isDetail = false;
            this.showJoin = false;
            this.isUserInfo = false;
            if (val.indexId != -1) {
                this.tabBarNavTemplate(val.indexId);
            } else {
                this.pageComponent = [
                    {
                        pageComponentName: this.getTemplateType("-1"),
                        pageComponentData: {},
                        pageComponentTitle: {},
                    },
                ];
                setTimeout(() => {
                    this.isPosition = utils.isHeightShow();
                }, 500);
            }
        },
        //点击公司logo返回首页
        clickLogo() {
            this.getApply();
            // this.isDetail = false;
            // this.showJoin = false;
            // this.isUserInfo = false;
            // if (this.activePageID != 1) {
            //     this.loadGetPageInfo();
            // }
        },
        //获取页面模板
        async loadGetPageInfo() {
            let res = await getHomePageData();
            if (res.code != 200) {
                return;
            }

            this.activePageID = res.data.indexFlag;
            this.pageComponent = [];
            let pageArray = [];
            res.data.moduleJsonData.forEach((item, index) => {
                pageArray.push({
                    pageComponentName: this.getTemplateType(item.id),
                    pageComponentData: item.data,
                    pageComponentTitle: {
                        name: item.name,
                        slogan: item.slogan,
                        imgUrl: item.url,
                        content: item.content,
                        price: item.price,
                        uuId: item.uuId,
                        id: item.id,
                    },
                });
            });
            this.isPage = true;
            this.pageComponent = JSON.parse(JSON.stringify(pageArray));
            console.log("页面信息", this.pageComponent);
            setTimeout(() => {
                this.isPosition = utils.isHeightShow();
            }, 500);
        },
        //获取模板信息
        async tabBarNavTemplate(id) {
            let res = await getPageData(id);
            this.activePageID = res.data.indexFlag;
            this.pageComponent = [];
            res.data.moduleJsonData.forEach((item) => {
                this.pageComponent.push({
                    pageComponentName: this.getTemplateType(item.id),
                    pageComponentData: item.data,
                    pageComponentTitle: {
                        name: item.name,
                        slogan: item.slogan,
                        imgUrl: item.url,
                        content: item.content,
                        price: item.price,
                        uuId: item.uuId,
                        id: item.id,
                    },
                });
            });
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            this.isPage = true;
            setTimeout(() => {
                this.isPosition = utils.isHeightShow();
            }, 500);
        },

        getTemplateType(type) {
            switch (type) {
                case "1":
                    return "banner-img"; //轮播图
                case "2":
                    return "service-support"; //服务支持
                case "3":
                    return "case-list"; //商用案列
                case "4":
                    return "customer-list"; //客户列表
                case "5":
                    return "news"; //新闻
                case "6":
                    return "judicial-list"; //底部单选产品介绍
                case "7":
                    return "domain"; //左列表右图片产品介绍
                case "8":
                    return "goods-txt"; //文本产品介绍
                case "9":
                    return "news-list"; //新闻列表分页
                case "10":
                    return "flow-path"; //流程
                case "11":
                    return "pageVideo"; //视频
                case "12":
                    return "auto-info"; //关于我们
                case "13":
                    return "faq-list"; //faq列表
                case "14":
                    return "goods"; //商品
                case "15":
                    return "join"; //三个添加的服务模块
                case "16":
                    return "caseVideo"; //案列视频
                case "17":
                    return "formDetail"; //表单
                case "18":
                    return "news-list"; //案例视频模块
                case "-1":
                    return "activity-list"; //案例视频模块
            }
        },
    },
};
</script>

<style>
.bannerImg {
    width: 100%;
}
.el-carousel__button {
    background-color: #d8d8d8;
}
.is-active button {
    background-color: var(--themeColor);
}
.height100 {
    height: calc(100vh);
}
</style>
