import request from '@/utils/request.js';

//我的活动申请列表
export function getActivityList(data) {
    return request.get(`/specialist/activity/list`,data)
};
//活动详情
export function getActivityDetail(id) {
    return request.get(`/specialist/activity/detail/${id}`)
};

//根据活动ID获取模块数据
export function getActivitModuleForm(activity) {
    return request.get(`/specialist/module/list/${activity}`)
};

//活动申请
export function postActivityApplyAdd(type,data) {
    return request.post(`/specialist/project/create/${type}`,data)
};

//活动申请修改
export function postActivityApplyUpdate(data) {
    return request.post(`/specialist/project/update/submit`,data)
};


//我的申请列表
export function getActivityApplyList(data) {
    return request.get(`/specialist/project/myApply`,data)
};

//申请列表状态
export function postActivityApplyStatus() {
    return request.post(`/specialist/project/listApplyStatus`)
};
//活动类型
export function postActivityApplyType(data) {
    return request.post(`/specialist/project/waitOperate/activityTypeList`,data)
};

//操作记录
export function postActivityApplyOperationRecord(data) {
    return request.post(`/specialist/project/operationRecord/list`,data)
};

//导出申请
export const postActivityApplyExport=`/specialist/project/waitOperate/export`;

//分页申请列表待处理
export function postActivityApplyList(data) {
    return request.post(`/specialist/project/waitOperate/list`,data)
};

//操作统计
export function postActivityApplyTotal() {
    return request.post(`/specialist/project/waitOperate/getWaitHandleAndFinishedCount`)
};

//获取申请活动ID获取承诺书
export function getActivityPromise(id) {
    return request.get(`/specialist/activity/promiseLetter/${id}`)
};
//根据申请ID获取表单详情
export function getActivityFormDetail(id) {
    return request.get(`/specialist/project/detail/${id}`)
};

//根据申请ID导出申请信息
export const postActivityApplyInfoExport = '/specialist/project/waitOperate/exportBaseMessage/';//需要添加ID

//根据申请ID导出附件
export const postActivityApplyFileExport = '/specialist/project/waitOperate/exportAppendix/';//需要添加ID


//审批历史
export function getApplyCheckHistory(projectId) {
    return request.get(`/specialist/project/review/history/${projectId}`)
};

//用户查看驳回评审
export function getApplyReject(projectId) {
    return request.get(`/specialist/project/review/reject/${projectId}`)
};

//评审
export function postApplyReview(data) {
    return request.post(`/specialist/project/review/auditProject`,data)
};

//获取评分规则
export function getScoringRules(activityId) {
    return request.get(`/specialist/activity/rule/getByActivityId/${activityId}`)
};
//根据项目ID获取评分标准
export function getScoringStandard(projectId) {
    return request.get(`/specialist/activity/rule/getByProjectId/${projectId}`)
};

//获取专家最终得分
export function getProjectScore(projectId) {
    return request.get(`/specialist/project/review/specialistReviewRecordList/${projectId}`)
};

//根据ID获取当前专家评分
export function getProjectCurrentScore(projectId) {
    return request.get(`/specialist/project/specialistScoring/listDeatilByProjectId/${projectId}`)
};

//查看专家评审
export function getHistoryDetail(projectId,id) {
    return request.get(`/specialist/project/review/historyByReviewUser/${projectId}?reviewUserId=${id}`)
};


//分页查询专家列表
export function getExpertList(data) {
    return request.get(`/specialist/assign/user/list`,data)
};

//绑定专家
export function postBindExpert(data) {
    return request.post(`/specialist/assign/bindSpecialist`,data)
};

//用户签署承诺书
export function postUserSign(id) {
    return request.post(`/specialist/activity/promiseLetter/sign/${id}`)
};
