<template>
  <div>
     <el-dialog :visible.sync="centerDialogVisible" :title="title" width="50%" center>
            <p class="agreementTxt" v-html="content"></p>
            <div class="xieyiRows" v-if="title">
                    <el-checkbox v-model="isAgreement">已阅读并同意 </el-checkbox> <span>《{{title}}》</span>
                </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" size="small" @click="clickAgree">{{title?'我知道了':'同意'}}</el-button>
                </span>

            </template>
        </el-dialog>
  </div>
</template>

<script>
export default {
    props:{
        content:String,
        title:String,
    },
    data(){
        return{
            centerDialogVisible:false,
            isAgreement:false,
        }
    },
    methods:{
        clickAgree(){
            if(this.title && !this.isAgreement){
                this.$message.error('请勾选同意协议');
                return;
            }
            this.centerDialogVisible = false;
        
            this.$emit('agree');
        }
    }
}
</script>

<style>
.agreementTxt{
    background: #f5f5f5;
    padding: .15rem;
    border-radius: .06rem;
    height: 6rem;
    overflow: auto;
}
.xieyiRows {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: .1rem;
}
</style>