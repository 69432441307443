<template>
    <div class="pagePadding">
        <modules-title :title="title.name" :detail="title.slogan"></modules-title>
        <div class="iconList">
            <div class="iconItem" :class="item.jumpType?'cursor':''" v-for="(item,index) in listImg" :key="index">
                <img style="height:20px" :style="{height:item.heightSize+'rem',width:item.widthSize+'rem'}" :src="item.url" @click="$emit('navUrl',item)">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Array,
        title: Object,
    },
    watch:{
        data(val){
            this.getImageInfo();
        }
    },
    data() {
        return {
            listImg: [], //列表图片
        };
    },
    mounted(){
        this.getImageInfo();
    },
    methods: {
        //获取图片信息
        async getImageInfo() {
            let that=this;
            this.listImg=[];
            for(let i=0; i<this.data.length; i++){
                let imageUrl = process.env.VUE_APP_BASE_API + this.data[i].url;
                let imgSize= await that.getImgSize(imageUrl)
                this.listImg.push({
                     url: imageUrl,
                     jumpUrl:this.data[i].jumpUrl,
                     jumpType:this.data[i].jumpType,
                    ...imgSize
                })
            }

            
        },
        getImgSize(imgUrl) {
            let img = new Image();
            img.src = imgUrl;
            return new Promise((resolve, reject) => {
                let heightSize, widthSize;
                img.onload = function () {
                    widthSize = Number(img.width) / 100 / 2;
                    heightSize = Number(img.height) / 100 / 2;
                    resolve({
                        widthSize,
                        heightSize,
                    });

                };
            });
        },
    },
};
</script>

<style lang="less">
    .iconList {
        display: flex;
        flex-wrap: wrap;
        width: 14.1rem;
        .iconItem {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.2rem;
            height: 1.1rem;
            margin-right: 0.15rem;
            margin-bottom: 0.3rem;
            box-shadow: 0px 0px 4px 0px rgba(60, 131, 255, 0.5);
            border-radius: 0.06rem;
        }
    }

</style>
