<template>
    <div class="flow">
        <div class="flowItem" v-for="(item,index) in data" :key="index">
                <div class="flowImgDiv">
                    <div class="imgDiv">
                        <img :src="baseUrl+item.url" alt="">
                        <!-- <span class="flowTxt theme_color">收集需求</span> -->
                    </div>
                    <img v-if="index!=data.length-1" class="flowIcon" src="@/assets/image/flow.png" alt="">
                </div>
                <h3 class="font22">{{item.title}}</h3>
                <p class="font18">{{item.content}}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: Array,
    },
    data(){
        return{
         baseUrl: process.env.VUE_APP_BASE_API

        }
}
};
</script>

<style scoped lang="less">
.flow {
    display: flex;
    justify-content: center;
    
    margin: 1.4rem 0;
    padding: 0 2.6rem;
    .flowItem {
        .flowImgDiv {
            display: flex;
            align-items: center;
            margin-bottom: 0.2rem;
            .imgDiv {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                width: 2.2rem;
                height: 2.2rem;
                border-radius: 0.2rem;
                box-shadow: 0px 2px 16px 0px rgba(1, 78, 181, 0.2);
                img {
                    height: 1rem;
                    width: 1rem;
                }
                .flowTxt {
                    font-size: 0.16rem;
                }
            }
        }
        .flowIcon {
            height: 0.2rem;
            width: 0.87rem;
            margin: 0 0.4rem;
        }
        h3 {
            width: 2.2rem;
            margin-bottom: 0.14rem;
            text-align: center;
            font-weight: 500;
        }
        p {
            width: 2.2rem;
        }
    }
}
</style>
