import request from '@/utils/request'

// 用户详情
export function getUserDetail() {
    return request.get( `/specialist/user/detail`)
}
// 用户绑定企业审核
export function postUserCompanyBindingCheck(data) {
    return request.post( `/specialist/enterprise/auditBindingEnterprise`,data)
}
// 解绑用户
export function postUserCompanyUnding(data) {
    return request.post( `/specialist/enterprise/unbindingEnterprise`,data)
}
// 绑定企业
export function postUserCompanyBinding(data) {
    return request.post( `/specialist/enterprise/bingdingEnterprise`,data)
}
// 用户企业列表
export function postCompanyUserList(data) {
  return request.post( `/specialist/enterprise/userList`,data)
}
// 企业类型
export function postCompanyType() {
    return request.post( `/specialist/enterprise/enterpriseTypeList`)
}

//查看企业用户
export function getCompanyUserDetail(userId) {
    return request.get(`/specialist/user/userInfo/${userId}`)
  }

  
//导出企业用户
export const postCompanyUserExport = '/specialist/enterprise/export';