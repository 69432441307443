<template>
    <div class="info">
        <div class="content fontColor">
            <span class="title">{{title.name}}</span>
            <span class="border theme_bg"></span>
            <span class="desc">{{title.slogan}}</span>
        </div>
        <div class="rightInfo">
            <p class="infoTxt fontColor">{{title.content}}</p>
            <img :src="baseUrl + title.imgUrl" >
        </div>
    </div>
</template>

<script>
import modulesTitle from "@/components/modulesTitle/index";
export default {
    components: {
        modulesTitle,
    },
     props: {
        data: Array,
        title: Object,
    },
    data() {
        return {
            baseUrl: process.env.VUE_APP_BASE_API
        };
    },
    created() {},
    methods: {},
};
</script>

<style scoped lang="less">
.info{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem 2.6rem;
    .rightInfo{
        width: 10rem;
        .infoTxt{
            font-size: .17rem;
            line-height: .34rem;
            white-space: pre-wrap
        }
        img{
            width: 100%;
            height: 5rem;
            margin-top: .6rem;
            border-radius: .16rem;
        }
    }
}
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span{
        margin-bottom: .15rem;
    }
}
.title {
    font-size: .4rem;
    font-weight: 600;
    line-height: .56rem;
}
.border {
    width: 0.6rem;
    height: 0.04rem;
    border-radius: 0.02rem;
}
.desc {
    font-size: .18rem;
    line-height: .26rem;
}
</style>
