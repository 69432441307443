import Cookies from 'js-cookie'

const TokenKey = 'PC-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function isToken() {
  let istoken=Cookies.get(TokenKey);
  if(istoken){
    return true
  }else{
    return false
  }
}