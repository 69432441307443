<template>
    <div class="news">
        <div class="newsContent">
            <modules-title :title="title.name" :detail="title.slogan"></modules-title>
            <div class="newsList">
                <div class="leftList">
                    <div class="newsItem" v-for="(item,index) in data" :key="index" @click="clickNewsItem(item)">
                        <span class="newsTxt cursor">{{item.title}}</span>
                        <span class="newsDate">{{item.time}}</span>
                    </div>
                </div>
                <img class="rightImg" :src="baseUrl+title.imgUrl">
            </div>
        </div>
        <!-- <div> <el-button @click="clickNewsItem('more')">更多</el-button></div> -->
    </div>
</template>

<script>
import modulesTitle from "@/components/modulesTitle/index";
export default {
    components: {
        modulesTitle,
    },
    props: {
        data: Array,
        title: Object,
    },
    data(){
        return{
             baseUrl: process.env.VUE_APP_BASE_API
        }
    },
    created(){
    },
    methods:{
        clickNewsItem(item){
            this.$emit('itemNav',{data:item,type:'news'});
        }
    },

};
</script>
<style lang="less">
.news {
    margin-bottom: 1.3rem;
    padding-bottom: 0.5rem;
    background: #f5f8fc;
    text-align: center;
    .newsContent {
        display: inline-block;
        margin-bottom: 0.7rem;
        .newsList {
            display: flex;
            .leftList {
                display: flex;
                flex-direction: column;
                // justify-content: space-around;
                .newsItem {
                    width: 6.5rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .newsTxt {
                        font-size: 0.18rem;
                        width: 4.2rem;
                        margin-bottom:.3rem;
                        text-align: left;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        color: #333e51;
                    }
                    .newsTxt:hover{
                        color:var(--themeColor);
                    }
                    .newsDate {
                        font-size: 0.18rem;
                        color: #333e51;
                    }
                }
            }

            .rightImg {
                height: 3.8rem;
                width: 5.6rem;
                margin-left: 1.1rem;
                border-radius: 0.1rem;
            }
        }
    }
}
</style>
