<template>
  <div class="videopage">
      <modules-title :title="title.name" :detail="title.slogan"></modules-title>
      <video class="video" controls height="7.4rem" width="14rem"  :src="baseUrl+title.imgUrl"></video>
  </div>
</template>

<script>
import modulesTitle from "@/components/modulesTitle/index";
export default {
    components: {
        modulesTitle,
    },
     props: {
        data: Array,
        title: Object,
    },
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASE_API
        }
    }

}
</script>

<style lang="less">
.videopage{
    padding: 0 2.6rem;
    
}

</style>