<template>
    <div class="newsList pagePadding">
        <modules-title :title="title.name" :detail="title.slogan"></modules-title>
        <div class="listContent">
            <div class="newsItem cursor" v-for="(item,index) in newsList" :key="index" @click="clickNavDetail(item)">
                <img class="newsImg" :src="baseUrl + item.image" alt="">
                <div class="newsTxt">
                    <h3 class="newsTitle font20">{{item.title}}</h3>
                    <span class="font16">{{item.createTime}}</span>
                </div>
            </div>
        </div>
        <div class="pageCode">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size="6" 
            layout="total,prev, pager, next, jumper" :total="totalData">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { getFAQList, postCaseVideoList } from "@/api/home";
export default {
    props: {
        data: Array,
        title: Object,
    },
    watch:{
        title(){
            this.loadGetList();
        }
    },
    data() {
        return {
            queryForm: {
                pageNum: 1,
                pageSize: 6,
                typeId: null,
            },
            newsList: [], //新闻列表
            totalData: 0, //总数据
            baseUrl: process.env.VUE_APP_BASE_API,
        };
    },
    created() {
        
        this.loadGetList();
    },

    methods: {
        async loadGetList() {
            this.queryForm.typeId = this.title.content;
            let res = null;
            if (this.title.id == 18) {
                //如果是视频模块
                res = await postCaseVideoList(this.queryForm);
            } else {
                //如果是文章
                res = await getFAQList(this.queryForm);
            }
            if (res.code != 200) {
                return;
            }
            this.totalData = res.data.total;
            this.newsList = res.data.records;
            this.newsList = this.newsList.map((item) => {
                let createTime = item.createTime.split(" ");
                item.createTime = createTime[0];
                return item;
            });
        },
        clickNavDetail(item) {
            this.$emit("itemNav", {
                data: item,
                type: this.title.id == 18 ? "video" : "news",
            });
        },
        handleSizeChange(val) {
            this.queryForm.pageSize = val;
            this.loadGetList();
        },
        handleCurrentChange(val) {
            this.queryForm.pageNum = val;
            this.loadGetList();
        },
    },
};
</script>

<style scoped lang="less">
.listContent {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.2rem;
    .newsItem {
        width: 4.3rem;
        height: 4.06rem;
        margin-bottom: 0.6rem;
        margin-right: 0.3rem;
        .newsImg {
            height: 2.6rem;
            width: 100%;
            border-radius: 0.06rem 0.06rem 0 0;
        }
        .newsTxt {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 1.46rem;
            padding: 0.2rem;
            background: #f7f7f8;
            border-radius: 0 0 0.06rem 0.06rem;
            box-sizing: border-box;
            .newsTitle {
                font-weight: 600;
            }
        }
    }
    .newsItem:nth-child(3n) {
        margin-right: 0 !important;
    }
    .newsItem:nth-child(n+3){
        margin-bottom: .2rem;
    }
}
</style>
