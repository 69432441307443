import request from '@/utils/request.js';

//获取基本信息
export function companyInfo() {
    return request.get('/pc/info/query');
};

//获取导航栏信息
export function getNavData() {
    return request.get('/pc/nav_bar/query');
};

//获取首页模板
export function getHomePageData() {
    return request.get('/pc/index_set/get_home');
};

//根据ID获取页面模板
export function getPageData(id) {
    return request.get(`/pc/index_set/${id}`);
};

//FAQ分类列表
export function getFAQTypeList() {
    return request.get('/pc/faq_center/type_list');
};

//FAQ数据列表
export function getFAQList(params) {
    return request.post('/pc/faq_center/list',params);
};

//FAQ列表详情
export function getFAQDetail(id) {
    return request.get(`pc/faq_center/${id}`);
};

//咨询中心列表
export function getConsultList(params) {
    return request.get('/pc/cou_center/list',params);
};

//咨询中心列表详情
export function getConsultListDetail(id) {
    return request.get(`/pc/cou_center/${id}`);
};

//获取支付二维码
export function postPayCode(data) {
    return request.post(`/pc/pay/getPayCode`,data);
};

//提交订单
export function postOrderSubmit(data) {
    return request.post(`/pc/order/submitOrder`,data);
};


//获取支付类型
export function getPayTypeList() {
    return request.get(`/pc/pay_way/list/pc`);
};


//获取支付类型
export function getOrderInfo(orderNo) {
    return request.get(`/pc/order/${orderNo}`);
};

//查询加入我们
export function getAutoInfo() {
    return request.get(`/pc/cache/query`);
};


//是否开启登录注册
export function getLoginSwitch() {
    return request.get(`/pc/cache/queryNavigationSwitch`);
};
//用户是否已注册
export function getUserRegister(phone) {
    return request.get(`/pc/user/isExist/${phone}`,null,{isToken:false});
};
//发送验证码
export function getCode(phone) {
    return request.get(`/pc/user/sendAuthCode?telephone=${phone}&areaCode=${86}`,{isToken:false});
};
//用户注册
export function postUserRegister(data) {
    return request.post(`/pc/user/register`,data,{isToken:false});//去除token
};
//获取验证码
export function getCodeImg(data) {
    return request.get(`/captchaImage`,null,{isToken:false});//去除token
};
//校验是否已注册
export function getCheckUser(data) {
    return request.get(`/pc/user/isExist/${phone}`,null,{isToken:false});//去除token
};


//用户登录
export function postUserLogin(data) {
    return request.post(`/pc/user/login`,data,{isToken:false});//去除token
};

//重置密码
export function postResetPwd(data) {
    return request.post(`/pc/user/resetPwd`,data,{isToken:false});//去除token
};

//三级分类列表内容
export function postcouContentList(data) {
    return request.post(`/pc/cou_center/list`,data,{isToken:false});//去除token
};
//三级内容详情
export function getCouCenterDetail(id) {
    return request.get(`/pc/cou_center/${id}`,null,{isToken:false});//去除token
};
//查询一级下的所有二级
export function postContentTypeList(data) {
    return request.post(`/pc/contentType/list`,data,{isToken:false});//去除token
};

//查询案例视频列表
export function postCaseVideoList(data) {
    return request.post(`/pc/caseLive/list`,data,{isToken:false});//去除token
};
//查询案例视频详情
export function getCaseVideoDetail(id) {
    return request.get(`/pc/caseLive/${id}`,null,{isToken:false});//去除token
};

//查询个人信息
export function getUserDetail() {
    return request.get(`/pc/user/userCenter`,null,{isToken:false});//去除token
};

//查询表单详情
export function getFormDetail(id) {
    return request.get(`/pc/form/${id}`,null,{isToken:false});//查询表单
};

