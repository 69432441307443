<template>
    <div>
        <el-carousel v-if="data.length>1" :interval="7000" height="8.9rem">
            <el-carousel-item v-for="(item,index) in data" :key="index">
                <div class="banner">
                    <img class="bannerImg" :src="baseUrl + item.url">
                    <div class="bannerInfo">
                        <p class="bannerTitle">{{item.mianTxt}}</p>
                        <p class="bannerTxt">{{item.viceTxt}}</p>
                        <el-button type="primary" class="bannerBtn" @click="$emit('navUrl',item)">{{item.btnTxt}}</el-button>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
        <img v-else class="bannerImg" :src="baseUrl + data[0].url" @click="$emit('navUrl',data[0])">
    </div>
</template>

<script>
export default {
    props: {
        data:  Array,
    },
    data() {
        return {
            baseUrl: process.env.VUE_APP_BASE_API,
        };
    },
};
</script>

<style lang="less">
.banner {
    position: relative;
    height: 8.9rem;
    .bannerImg {
        width: 100%;
        height: 100%;
        animation: scale11 7s infinite;
        transform: scale(1);
        transition: all 0.8s ease;
    }
    @keyframes scale11 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.16);
        }
    }
    .bannerInfo {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        // justify-content: center;
        padding-top: 1.2rem;
        align-items: center;
        flex-direction: column;
        background: rgba(0, 0, 0, 0.3);
        .bannerTitle {
            font-size: 0.6rem;
            font-weight: 600;
            color: #ffffff;
            margin-bottom: 0.2rem;
        }
        .bannerTxt {
            font-size: 0.3rem;
            font-weight: 600;
            color: #eeeeee;
            margin-bottom: 0.2rem;
        }
        .bannerBtn {
            // background: #0072FF;
            border: none;
            // font-size: .3rem;
        }
    }
}
</style>
