<template>
    <div class="newsList pagePadding">
        <modules-title title="案例申报入口" ></modules-title>
        <div class="listContent" :class="{centetn:newsList.length<3}">
            <div class="newsItem cursor" :class="{maxItem:newsList.length==1}" v-for="(item,index) in newsList" :key="index" @click="clickNavDetail(item)">
                <img class="newsImg" :class="{maxImg:newsList.length==1}" :src="item.imageUrl" alt="">
                <div class="newsTxt" :class="{maxTxt:newsList.length==1}">
                    <h3 class="newsTitle font20">{{item.activityName}}</h3>
                    <span class="font16">{{item.createTime}}</span>
                </div>
            </div>
        </div>
        <div class="pageCode">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size="6" 
            layout="total,prev, pager, next, jumper" :total="totalData">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {getActivityList } from "@/api/activity";
export default {
    data() {
        return {
            queryForm: {
                pageNum: 1,
                pageSize: 6,
            },
            newsList: [], //新闻列表
            totalData: 0, //总数据
            baseUrl: process.env.VUE_APP_BASE_API,
        };
    },
    created() {
        
        this.loadGetList();
    },

    methods: {
        async loadGetList() {
            let res = await getActivityList(this.queryForm);
            if (res.code != 200) {
                return;
            }
            this.totalData = res.data.rows;
            this.newsList = res.data.records;
            this.newsList = this.newsList.map((item) => {
                let expireTime = item.expireTime.split(" ");
                item.expireTime = expireTime[0];
                return item;
            });
            console.log('数据：',this.newsList);
        },
        clickNavDetail(item) {
            console.log('跳转');
            this.$router.push({path:'/activityDesc',query:{id:item.id}})
        },
        handleSizeChange(val) {
            this.queryForm.pageSize = val;
            this.loadGetList();
        },
        handleCurrentChange(val) {
            this.queryForm.pageNum = val;
            this.loadGetList();
        },
    },
};
</script>

<style scoped lang="less">
.centetn{
    justify-content: center;
}
.maxItem{
        width: 100% !important;
        height: 7rem !important;
        margin: 0 !important;
    }

    .maxImg{
        width: 100% !important;
        height: 4.90rem !important;
    }
    .maxTxt{
        height: 2.1rem !important;
    }
.listContent {
    display: flex;
    
    flex-wrap: wrap;
    margin-bottom: 0.2rem;
    

    .newsItem {
        width: 31%;
        height: 4.06rem;
        margin-bottom: 0.6rem;
        margin-right: 0.3rem;
        .newsImg {
            height: 2.6rem;
            width: 100%;
            border-radius: 0.06rem 0.06rem 0 0;
        }
        .newsTxt {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 1.46rem;
            padding: 0.2rem;
            background: #f1f1f1;
            border-radius: 0 0 0.06rem 0.06rem;
            box-sizing: border-box;
            .newsTitle {
                font-weight: 600;
            }
        }
    }
    .newsItem:nth-child(3n) {
        margin-right: 0 !important;
    }
    .newsItem:nth-child(n+3){
        margin-bottom: .2rem;
    }
}
</style>
