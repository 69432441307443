<template>
    <div class="pagePadding">
        <modules-title :title="title.name" :detail="title.slogan"></modules-title>
        <div class="form">
            <el-form ref="ruleForm" :model="formData" size="medium" label-position="right" label-width="100px" :rules="rules">
                <draggable-item v-for="(element, index) in formdataList" :key="element.renderKey" :drawing-list="formdataList" :element="element" :index="index" :form-conf="formConf" />
            </el-form>

            <div class="btnCenter">
                <el-button type="primary" @click="clickSubmit" class="theme_bg">提 交</el-button>
            </div>
        </div>

    </div>
</template>

<script>
import { getFormDetail } from "@/api/home";
import draggableItem from "@/views/home/viewDraggableItem";
import { formConf } from "@/utils/form/formConfig";
import {postSubmitApply} from '@/api/apply'
export default {
    props: {
        title: Object,
    },
    components: {
        draggableItem,
    },
    data() {
        return {
            formdataList: [], //表单列表
            formConf,
            rules: {},

            formData:{},//获取表单
        };
    },
    created() {
        this.loadGetForm();
    },
    methods: {
        async loadGetForm() {
            let res = await getFormDetail(this.title.content);
            if (res.code == 200) {
                this.formdataList = res.data.controlsJson;

                this.formdataList.forEach((item) => {
                    this.$set(this.formData,item.vModel,item.defaultValue)
                    if (item.required) {
                        this.$set(this.rules, item.vModel, [
                            {
                                required: true,
                                message: item.placeholder,
                                trigger: "blur",
                            },
                        ]);
                    }
                });
            }
        },

        //提交表单
        async clickSubmit() {
            console.log("提交表单：", this.formdataList);
            let isErr=false;
            this.formdataList.forEach((item)=>{
                if(item.required){
                    if(!item.defaultValue || item.defaultValue.length==0){
                        console.log('未输入',item);
                        isErr=true;
                        
                    }
                }
            })
            if(isErr){
                this.$message.error('有必填未输入');
                return;
            }

            let res = await postSubmitApply({controls:JSON.stringify(this.formdataList)});
                if (res.code == 200) {
                    this.$message({
                        type: "success",
                        message: "已提交申请",
                    });
                }

        },
    },
};
</script>

<style lang="less">
</style>