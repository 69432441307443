<template>
  <div class="imgInfo">
      <img src="@/assets/image/banner.png" >
  </div>
</template>

<script>
export default {
    props:{
        imgUrl:String,
    }

}
</script>

<style lang="less">
.imgInfo{
    height: 4.6rem;
    img{
        width: 100%;
        height: 100%;
    }
}

</style>