<template>
    <div class="authForm">
        <el-dialog class="show" :visible.sync="show" width="750px">
            <div class="authPadding">
                <h1>企业认证</h1>
                <el-form ref="companyForm" :model="companyForm" :rules="authRules" class="login-form" size="medium ">
                    <el-form-item prop="roleId" label="企业类型">
                        <el-radio-group v-model="companyForm.type">
                            <el-radio v-for="(item,index) in typeList" :key="index" :label="item.enterpriseTypeCode">{{item.enterpriseTypeDesc}}</el-radio>
                            <!-- <el-radio :label="1">个体工商户</el-radio>
                            <el-radio :label="2">党政机关</el-radio>
                            <el-radio :label="3">事业单位</el-radio>
                            <el-radio :label="4">社会组织活其它组织</el-radio> -->
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item prop="name" label="企业名称">
                        <el-input type="text" v-model="companyForm.name" placeholder="请输入企业名称" size="default"></el-input>
                        <div class="nameTips">需严格按照营业执照填写。如个体工商户营业执照无企业名称时（包括**、“无字号”或空等情况），请以“个体户+经营者姓名”的形式填写。如：个体户张三</div>
                    </el-form-item>
                    <el-form-item prop="businessLicenseCode" label="营业执照注册号">
                        <el-input type="text" v-model="companyForm.businessLicenseCode" placeholder="请输入营业执照注册号" size="default"></el-input>
                    </el-form-item>
                    <el-form-item prop="legalPerson" label="企业法人代表">
                        <el-input type="text" v-model="companyForm.legalPerson" placeholder="请输入企业法人代表" size="default"></el-input>
                    </el-form-item>
                    <el-form-item prop="legalPersonCard" label="法人身份证号">
                        <el-input type="text" v-model="companyForm.legalPersonCard"  onKeyUp="value=value.replace(/[\W]/g,'')" maxlength="18" placeholder="请输入法人身份证号" size="default"></el-input>
                    </el-form-item>
                     <el-form-item prop="businessLicenseFileId" label="企业营业执照文件">
                        <fileUpload @input="inputFile" :value="companyForm.businessLicenseFileId" :limit="1" :fileType="['doc','docx','pdf','png','jpg']"></fileUpload>
                    </el-form-item>
                   
                    <!-- <el-form-item prop="authenType" label="认证类型">
                        <el-radio-group v-model="companyForm.authenType">
                            <el-radio :label="0">打款认证</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item prop="bankAccountName" label="企业银行账户名称">
                        <el-input type="text" v-model="companyForm.bankAccountName" placeholder="请输入企业银行账户名称" size="default"></el-input>
                    </el-form-item>
                    <el-form-item prop="bankCode" label="企业银行卡号">
                        <el-input type="text" v-model="companyForm.bankCode" placeholder="请输入企业银行卡号" size="default"></el-input>
                    </el-form-item>
                    <el-form-item prop="bankAttribution" label="银行卡所属银行">
                        <el-input type="text" v-model="companyForm.bankAttribution" placeholder="请输入银行卡所属银行" size="default"></el-input>
                    </el-form-item>
                    <el-form-item prop="price" label="输入金额（元）">
                        <el-input style="width:55%" type="number" v-model="companyForm.price" placeholder="例：0.01 - 0.99" size="default"></el-input>
                        <el-button style="margin-left:.15rem" type="primary">发起打款请求</el-button>
                    </el-form-item> -->
                </el-form>
                <div class="btnCenter" style="margin-top:.4rem">
                    <span class="authBtn cursor" @click="clickSumbit">认 证</span>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import {postCompanyType} from '@/api/company';
import { postSubmitCompany } from "@/api/apply.js";
import checkCode from "@/components/login/checkCode.vue";
import fileUpload from '@/components/common/fileUpload.vue'
export default {
    components: {
        checkCode,
        fileUpload
    },

    data() {
        return {
            roleList: [],
            typeList:[],
            show: false,
            companyForm: {
                type: '0', //企业类型
                name: "", //企业名称
                businessLicenseCode: "", //营业执照号
                legalPerson: "", //法人代表
                legalPersonCard: "", //法人身份证号
                businessLicenseFileId:'',
                // authenType: 0, //认证类型 0打款认证
                // bankAccountName: "",
                // bankCode: "",
                // bankAttribution: "",
                // price: null,
            },
            authRules: {
                name: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入企业名称",
                    },
                ],
                businessLicenseCode: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入营业执照号",
                    },
                ],
                legalPerson: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入法人代表",
                    },
                ],
                legalPersonCard:[
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入法人身份证号",
                    },
                ],
                 businessLicenseFileId:[
                    {
                        required: true,
                        trigger: "blur",
                        message: "请上传企业营业执照",
                    },
                ],
                // bankAccountName: [
                //     {
                //         required: true,
                //         trigger: "blur",
                //         message: "请输入银行卡账户名称",
                //     },
                // ],
                // bankCode: [
                //     {
                //         required: true,
                //         trigger: "blur",
                //         message: "请输入企业银行卡号",
                //     },
                // ],
                // bankAttribution: [
                //     {
                //         required: true,
                //         trigger: "blur",
                //         message: "请输入银行卡所属银行",
                //     },
                // ],
                // price: [
                //     {
                //         required: true,
                //         trigger: "blur",
                //         message: "请输入打款金额",
                //     },
                // ],
            },
        };
    },
    created() {
        this.companyForm.phone = localStorage.getItem("phone_key");
        this.getAllType();
    },
    methods: {
        async getAllType(){
            let res = await postCompanyType();
            if(res.code==200){
                this.typeList=res.data;
            }
        },
        async clickSumbit() {
            let isErr = false;
            this.$refs["companyForm"].validate((valid) => {
                if (!valid) {
                    isErr = true;
                }
            });
            if (isErr) {
                return;
            }
            let res = await postSubmitCompany(this.companyForm);
            if (res.code == 200) {
                  this.$message({
                     type:'success',
                     message:'提交成功'
                 })
                 this.show=false;
                 this.$emit('submit')
            }
        },
        //文件上传回调
        inputFile(val){
            this.companyForm.businessLicenseFileId=val.id;
            console.log('文件ID', this.companyForm.businessLicenseFileId);
        },
    },
};
</script>

<style lang="less" scoped>
.authPadding {
    padding: 0.1rem 0.45rem;
    h1 {
        margin-bottom: 0.3rem;
        font-size: 0.21rem !important;
    }

    h2 {
        margin-top: 0.5rem;
        margin-bottom: 0.15rem;
        font-size: 0.21rem !important;
    }
}
.authBtn {
    display: inline-block;
    width: 1.2rem;
    height: 0.5rem;
    background: #025cec;
    color: #fff;
    border-radius: 0.05rem;
    font-size: 0.17rem;
    line-height: 0.5rem;
}
.nameTips {
    font-size: 16px;
    font-weight: 400;
    color: #999999;
}
:deep(.el-radio-button__inner) {
    width: 1.08rem !important;
    height: 0.45rem;
    margin-right: 0.2rem;
    font-size: 0.18rem !important;
    font-weight: 400;
    padding: 0;
    line-height: 0.45rem;
    color: #333333;
    border-radius: 0.04rem !important;
    background-color: #f5f5f5;
    border: none !important;
}
:deep(.el-form-item__label) {
    font-size: 0.18rem;
}
</style>