<template>
    <div class="goodsTxt">
        <modules-title :title="title.name" :detail="title.slogan"></modules-title>
        <div class="jieshao font20">{{title.content}}</div>
    </div>
</template>

<script>
import modulesTitle from "@/components/modulesTitle/index";
export default {
    components: {
        modulesTitle,
    },
    props: {
        data: Array,
        title: Object,
    },
    created(){
        console.log('模板3：',this.title);
    },
    

};
</script>

<style>
.goodsTxt {
    padding: 0 2.6rem;
}
.jieshao {
    white-space: pre-wrap;
}
</style>