<template>
    <div class="goods">
        <modules-title :title="title.name" :detail="title.slogan"></modules-title>
        <div class="goodsInfo">
            <div class="goodsLeft">
                <img :src="baseUrl+title.imgUrl" alt="">
                <div class="info">
                    <p class="fontColor font16"> 商品名称：{{title.name}}</p>
                    <div class="fontColor font16">商品金额：<span class="priceTxt">{{title.price}} 元</span> </div>
                </div>
            </div>
            <div class="goodsRight">
                <el-form label-width="80px" label-position="left">
                    <el-form-item v-for="(item,index) in form.jsonData" :key="index" :label="item.title">
                        <el-input v-model="item.content" style="width:3.5rem"></el-input>
                    </el-form-item>
                    <el-form-item label="支付方式">
                        <el-radio-group v-model="bankCode">
                            <el-radio v-for="(item,index) in payTypeList" :key="index" :label="item.bankCode">{{item.name}}</el-radio>
                        </el-radio-group>

                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="bottmCenter">
            <el-tag type="info">提示：目前暂只支持微信支付、支付宝支付</el-tag>
            <el-button style="margin-top:.16rem" type="primary" @click="clickSumitData">确定提交</el-button>
        </div>
        <el-dialog v-model="centerDialogVisible" title="扫描二维码支付" width="5rem" center @close="isTime=false">
            <el-form class="formClass" label-width="80px" label-position="left" disabled>
                <el-form-item v-for="(item,index) in form.jsonData" :key="index" :label="item.title">
                    <el-input v-model="item.content" style="width:3.5rem"></el-input>
                </el-form-item>
            </el-form>
            <div class="qrInfo">
                <div style="margin-bottom:.07rem" class="font18 fontColor">{{title.name}}</div>
                <div class="font18 fontColor ">{{title.price}}元</div>
                <vue-qr :text="payCode" :size="160" :logoSrc="payLogo" :logoScale="0.2"></vue-qr>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import modulesTitle from "@/components/modulesTitle/index";
import vueQr from "vue-qr/src/packages/vue-qr.vue";
import {
    postPayCode,
    postOrderSubmit,
    getPayTypeList,
    getOrderInfo,
} from "@/api/home.js";
export default {
    components: {
        modulesTitle,
        vueQr,
    },
    props: {
        data: Array,
        title: Object,
    },
    data() {
        return {
            centerDialogVisible: false,
            form: {
                id:null,
                goodName: "",
                uuId:0,
                jsonData: [],
            }, //列表图片
            bankCode: "", //WECHAT微信   ALIPAY支付宝
            payTypeList: [], //支付类型
            payCode: "", //支付二维码
            payLogo: "", //支付logo
            orderSn: "", //订单号
            isTime: true, //是否循环
             baseUrl: process.env.VUE_APP_BASE_API
        };
    },
    created() {
        this.form.goodName = this.title.name;
        this.form.uuId=this.title.uuId;
        this.form.jsonData = JSON.parse(JSON.stringify(this.data));
        this.form.jsonData = this.form.jsonData.map((item) => {
            return {
                title: item.title,
                content: item.content,
            };
        });
        this.loadGetPayList();
    },
    methods: {
        async loadGetPayList() {
            let res = await getPayTypeList();
            if (res.code == 200) {
                this.payTypeList = res.data;
            }
        },
        //点击提交数据
        async clickSumitData() {
            if (this.bankCode == "") {
                this.$message.error("请选择支付方式");
                return;
            }
            this.form.id=this.title.id;
            let res = await postOrderSubmit(this.form);
            if (res.code == 200) {
                this.orderSn = res.data.orderSn;
                let res1 = await postPayCode({
                    orderSn: res.data.orderSn,
                    bankCode: this.bankCode,
                });
                if (res1.code == 200) {
                    let activePay = this.payTypeList.filter((item) => {
                        return item.bankCode == this.bankCode;
                    }); //获取logo图片
                    this.payLogo = activePay[0].icon;

                    this.payCode = res1.data.qrCode;
                    this.centerDialogVisible = true;
                    this.isTime = true;
                    this.getOrderInfo(); //查询订单状态
                }
            }
        },

        //查询订单状态
        async getOrderInfo() {
            let res = await getOrderInfo(this.orderSn);
            if (res.code == 200) {
                if (res.data.payStatus == 1 && this.isTime) {
                    setTimeout(() => {
                        this.getOrderInfo(); //循环
                    }, 1000);
                } else if (res.data.payStatus == 2) {
                    this.$message({
                        message: "支付成功",
                        type: "success",
                    });
                    this.isTime=false;
                    this.centerDialogVisible=false;
                    
                }
            }
        },
    },
};
</script>

<style lang="less">
.goods {
    padding: 0 2.6rem;
    .goodsInfo {
        display: flex;
        // align-items: center;
        .goodsLeft {
            display: flex;
            width: 50%;
            border-right: 1px solid #e2e2e2;
            img {
                width: 1.64rem;
                height: 1.64rem;
            }
            .info {
                padding: 0.1rem;
                p {
                    margin-bottom: 0.15rem;
                }
                .priceTxt {
                    font-size: 0.24rem;
                    font-weight: 500;
                    color: #333333;
                    line-height: 33px;
                }
            }
        }
        .goodsRight {
            width: 50%;
            padding-left: 0.4rem;
        }
    }
    .bottmCenter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0.3rem;
    }
    .qrInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }
}
</style>
