<template>
    <div class="faqDetail" :class="isPosition?'positionRelative':''">
        <div class="faqDetailContent">
            <h1 class="pageTitle">{{pageContent.title}}</h1>
            <div class="dateLine">
                <span class="line"></span>
                <span class="dateTxt fontColor">{{pageContent.createTime}}</span>
                <span class="line"></span>
            </div>
            <div class="pageContent" v-html="pageContent.content"></div>
            <div class="videopage">
                <video v-if="type=='video' && pageContent.type==1" class="video" controls height="7.4rem" width="14rem" :src="baseUrl+pageContent.url"></video>

            </div>

            <div v-if="type=='faq'" class="formContent">
                <fileUpload v-if="pageContent.declareProcess==='0'" @input="uploadFile" :fileType="fileType"></fileUpload>
                <div class="down" v-if="pageContent.declareProcess==='0'">
                    <a class="cursor" :href="baseUrl+pageContent.declareValue">下载模板</a>
                </div>

                <div v-if="pageContent.declareProcess==='1'">
                    <el-form size="medium" label-position="right" label-width="100px">
                        <draggable-item v-for="(element, index) in formData" :key="element.renderKey" :drawing-list="formData" :element="element" :index="index" :form-conf="formConf" />
                    </el-form>
                </div>

                <div v-if="pageContent.declareProcess != '2'" class="btnCenter martop20">
                    <el-button type="primary" class="bottomBtn" @click="clicikSubmit">提交申请</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    getCouCenterDetail,
    getFAQDetail,
    getCaseVideoDetail,
} from "@/api/home";
import fileUpload from "@/components/upload/file";
import { getFormDetail,postSubmitApply  } from "@/api/apply";
import draggableItem from "@/views/home/viewDraggableItem";
import { formConf } from "@/utils/form/formConfig";
export default {
    props: {
        id: Number || String,
        type: String,
    },
    components: {
        draggableItem,
        fileUpload
    },
    data() {
        return {
            formConf,
            pageContent: {},
            isPosition: false, //是否需要定位
            baseUrl: process.env.VUE_APP_BASE_API,
            formData: [],
            fileType: ["doc", "txt", "pdf"],
            submitForm: {
                couCenterId: "",
                controls: "",
                fileUrl: "",
            },
        };
    },
    created() {
        this.loadGetDetailData();
    },
    methods: {
        async loadGetDetailData() {
            let res;
            if (this.type == "news") {
                res = await getFAQDetail(this.id);
            } else if (this.type == "video") {
                res = await getCaseVideoDetail(this.id);
            } else {
                res = await getCouCenterDetail(this.id);
                if (res.code == 200) {
                    if (res.data.declareProcess == 1) {
                        this.getFormDetail(res.data.declareValue);
                    }
                }
            }

            if (res.code != 200) {
                return;
            }
            res.data.createTime = res.data.createTime.split(" ")[0];
            this.pageContent = res.data;
            if (this.type == "video") {
                if (this.pageContent.type == 0) {
                    window.open(this.pageContent.url, "_blank");
                }
            }
        },
        //提交申请
        async clicikSubmit() {
            this.submitForm.couCenterId = this.id; //获取三级内容id
            console.log("表单：", this.submitForm);
            if (this.pageContent.declareProcess == 0) {
                if (!this.submitForm.fileUrl) {
                    this.$message.error("请上传附件");
                    return;
                }
                this.submitForm.controls = "";
                let res = await postSubmitApply(this.submitForm);
                if (res.code == 200) {
                    this.$message({
                        type: "success",
                        message: "已提交申请",
                    });
                }
                return;
            }
            if (!this.$utils.checkFormNull(this.formData)) {
                this.$message.error("有必填未输入");
                return;
            }
            this.submitForm.controls = JSON.stringify(this.formData);
            this.submitForm.fileUrl = "";
            let res = await postSubmitApply(this.submitForm);
            if (res.code == 200) {
                this.$message({
                    type: "success",
                    message: "已提交申请",
                });
            }
        },
        //查询表单
        async getFormDetail(id) {
            let res = await getFormDetail(id);
            if (res.code == 200) {
                this.formData = res.data.controlsJson;
            }
        },
        //文件上传回调
        uploadFile(val) {
            this.submitForm.fileUrl = val;
        },
    },
};
</script>

<style scoped lang="less">
.faqDetailContent {
    margin: 0 auto;
    .pageTitle {
        width: 8.74rem;
        text-align: center;
        margin: 0.5rem auto;
        font-size: 0.42rem;
        font-weight: 600;
    }
    .dateLine {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.46rem;
        .line {
            width: 5.8rem;
            height: 0.01rem;
            background: #bfcbdf;
        }
        .dateTxt {
            font-size: 0.14rem;
            margin: 0 0.5rem;
        }
    }
    .pageContent {
        margin: 0 auto;
        padding-bottom: 1rem;
        width: 14rem;
    }
}
.formContent{
    padding: .3rem 2.6rem;
}
.videopage {
    padding: 0 2.6rem;
}

 
</style>